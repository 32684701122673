import Spinner from '@/components/element/loading/Spinner';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useCampaignWithdrawFunds } from '@/features/campaigns/hooks/report/useCampaignWithdrawFunds';
import {
	CampaignRewardCategory,
	Status,
	distributionTypeMap,
	rewardTypeMap,
} from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import { useMemo } from 'react';
import { RewardContent } from './RewardContent';
import { RewardDetailRow } from './RewardDetailRow';
import { formatCompactNumber } from '@/utils/parsers';

export const RewardCard = ({ reward, tooltipText, campaignStatus }) => {
	const {
		isLoading: isWithdrawInProgress,
		claimTokens,
		txnStatus,
		isClaimable,
	} = useCampaignWithdrawFunds();

	const buttonState = useMemo(() => {
		if (isWithdrawInProgress) {
			return {
				label: 'Pending',
				icon: '',
				color: 'text-yellow-500',
				bgColor: 'bg-amber-100',
			};
		}
		if (txnStatus.isFailed) {
			return {
				label: 'Transaction Failed',
				icon: '',
				color: 'text-destructive',
				bgColor: 'bg-red-100',
			};
		}
		if (txnStatus.isSucceeded) {
			return {
				label: 'Refund Successful',
				icon: '',
				color: 'text-green-500',
				bgColor: 'bg-green-100',
			};
		}
		return {
			label: 'Request Refund',
			icon: '',
			color: '',
			bgColor: '',
		};
	}, [isWithdrawInProgress, txnStatus]);

	const showSeparator = useMemo(() => {
		return (
			reward.rewardClaimed != null ||
			reward.rewardWonButNotClaimed != null ||
			reward.rewardLeft != null
		);
	}, [reward]);

	return (
		<div className="bg-slate-50 rounded-lg p-4 flex flex-col h-full">
			<div className="flex-grow space-y-4">
				<p className="text-base text-slate-700 leading-5 font-semibold gap-1">
					{rewardTypeMap[reward.rewardType]}
					{reward.rewardType === CampaignRewardCategory.Token && (
						<TooltipWrapper
							tooltip={tooltipText}
							align="end"
							contentClassName="max-w-[22rem]"
						>
							<i className="bi-info-circle ms-1 text-sm" />
						</TooltipWrapper>
					)}
				</p>
				<Separator />
				{reward.rewardType !== CampaignRewardCategory.Token && (
					<RewardDetailRow
						label="Reward Name"
						value={reward?.rewardName}
					/>
				)}
				{reward.rewardPool !== null && (
					<RewardDetailRow
						label="Total Reward Pool"
						value={
							<RewardContent
								reward={reward}
								rewardType={reward.rewardType}
								rewardValue={reward.rewardPool}
								noSeparator
							/>
						}
					/>
				)}
				<RewardDetailRow
					label="Distribution Type"
					value={distributionTypeMap[reward.rewardDistributionType]}
				/>
				<RewardDetailRow
					label="Number of winners"
					value={
						reward.numberOfWinners != null ? (
							<span className="flex items-center">
								<i className="bi-people-fill text-slate-600 me-1"></i>
								{formatCompactNumber(reward.numberOfWinners)}
							</span>
						) : null
					}
				/>
				<RewardDetailRow
					label="Reward per user"
					value={
						reward.rewardPerUser != null ? (
							<span className="flex items-center">
								<i className="bi-people-fill text-slate-600 me-1"></i>
								{formatCompactNumber(reward.rewardPerUser)}
							</span>
						) : null
					}
				/>
				{showSeparator && <Separator />}
				<RewardDetailRow
					label="Claimed"
					value={
						reward.rewardClaimed != null ? (
							<span className="flex items-center">
								<i className="bi-people-fill text-slate-600 me-1"></i>
								{reward.rewardClaimed}
								<RewardContent
									reward={reward}
									rewardValue={reward.rewardClaimed}
									rewardPerUser={reward.rewardPerUser}
									rewardType={reward.rewardType}
								/>
							</span>
						) : null
					}
				/>

				<RewardDetailRow
					label="Won but not claimed"
					value={
						reward.rewardWonButNotClaimed != null ? (
							<span className="flex items-center">
								<i className="bi-people-fill text-slate-600 me-1"></i>
								{reward.rewardWonButNotClaimed}
								<RewardContent
									reward={reward}
									rewardValue={reward.rewardWonButNotClaimed}
									rewardPerUser={reward.rewardPerUser}
									rewardType={reward.rewardType}
								/>
							</span>
						) : null
					}
				/>

				<RewardDetailRow
					label="Unused"
					value={
						reward.rewardLeft != null ? (
							<span className="flex items-center">
								<i className="bi-people-fill text-slate-600 me-1"></i>
								{reward.rewardLeft}
								<RewardContent
									reward={reward}
									rewardValue={reward.rewardLeft}
									rewardPerUser={reward.rewardPerUser}
									rewardType={reward.rewardType}
								/>
							</span>
						) : null
					}
				/>
			</div>

			{reward.rewardType === CampaignRewardCategory.Token &&
				campaignStatus === Status.Completed &&
				isClaimable && (
					<div>
						<Separator className="my-5" />
						<Button
							onClick={claimTokens}
							disabled={
								isWithdrawInProgress ||
								txnStatus.isSucceeded ||
								txnStatus.isFailed
							}
							className={cn('shadow-none w-full')}
						>
							{isWithdrawInProgress && <Spinner className="me-2" />}
							{buttonState.label}
						</Button>
					</div>
				)}
		</div>
	);
};
