import { useEffect, useMemo } from 'react';
import { useConnectAPI } from '../hooks/useConnectAPI';
import useTestAPI from '../hooks/useTestAPI';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Badge } from '@/components/ui/badge';
import { ApiDataEnums, iconKeyMap } from '../types/task-apis.enum';
import clsx from 'clsx';
import InputDuration from '@/components/element/inputs/input-duration/InputDuration';
import { SelectTimeStamp } from '@/components/element/inputs/input-duration/SelectTimeStamp';
import useEventTimestamp from '../hooks/useEventTimestamp';

export const TestAPI = ({ setStep }) => {
	const { formErrors, formFields, setFormErrors } = useConnectAPI();

	const { handlePayloadInputTestValue } = useTestAPI();

	const { minStartTimestamp, endDate } = useEventTimestamp({
		startImmediately: true,
		startDate: new Date(),
	});

	const combinedPayload = useMemo(() => {
		const constantKeys = new Set(
			formFields.apiConstantDataFields.map((item) => item.key),
		);
		const apiPayloadWithFlag = formFields.apiPayload
			.filter((item) => !constantKeys.has(item.key))
			.map((item) => ({
				...item,
				isConstant: false,
			}));
		const constantDataWithFlag = formFields?.isCustomConstantPayloadRequired ? formFields.apiConstantDataFields.map(
			(item) => ({ ...item, isConstant: true })
		) : [];
		return [...apiPayloadWithFlag, ...constantDataWithFlag];
	}, [formFields.apiConstantDataFields, formFields.apiPayload, formFields?.isCustomConstantPayloadRequired]);

	useEffect(() => {
		setFormErrors({});
	}, [formFields.apiPayload, setFormErrors]);

	return (
		<div>
			<div className="w-full">
				{combinedPayload?.length > 0
					? combinedPayload?.map((item: any, index: number) => (
							<div key={item.key} className="my-4">
								<div
									className={clsx(
										'flex items-center border rounded-lg overflow-none',
										formErrors[item.key]
											? 'border-destructive'
											: '',
									)}
								>
									<div className="border-e h-9 flex items-center bg-muted rounded-s-md min-w-[157.7px]">
										<Label className="p-2 flex items-center text-black/70">
											<i
												className={`bi-${iconKeyMap[item.value] || 'braces-asterisk'} me-2 text-black/70`}
											></i>
											{item?.key?.charAt(0).toUpperCase() +
												item?.key?.slice(1)}
										</Label>
									</div>
									{item?.key ===
										ApiDataEnums?.EventStartTimestamp ||
									item?.key === ApiDataEnums?.EventEndTimestamp ? (
										<>{item.key}</>
									) : (
										<Input
											className={clsx(
												'border-none shadow-none w-full ring-0 focus:ring-0 focus:outline-none focus-visible:ring-0 focus-visible:outline-none',
											)}
											value={item?.testValue as string}
											onChange={(e: any) =>
												handlePayloadInputTestValue(
													e.target.value,
													index,
												)
											}
											disabled={item.isConstant}
										/>
									)}
								</div>

								{formErrors[item.key] ? (
									<p className="text-xs text-destructive mt-1 text-right">
										{formErrors[item.key]}
									</p>
								) : null}
							</div>
						))
					: null}

				<div className=" max-w-[505px] overflow-x-auto">
					{formFields?.isApiTestDone ? (
						<>
							{typeof formFields?.responseSchema === 'string' ? (
								<Textarea
									className="w-full h-32 bg-muted"
									value={formFields?.responseSchema}
									readOnly
								/>
							) : (
								<pre className="h-fit relative rounded bg-muted min-w-full w-fit p-4 font-mono text-sm font-medium">
									{JSON.stringify(
										formFields?.responseSchema,
										null,
										2,
									)}
								</pre>
							)}
							<div className="my-4">
								{formFields?.isApiSchemaValid ? (
									<Badge
										variant="secondary"
										className="bg-green-100 text-green-900"
									>
										Valid Response Type
									</Badge>
								) : (
									<Badge variant="destructive">
										Invalid Response Type
									</Badge>
								)}
							</div>
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};
