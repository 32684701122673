import { aboutPage } from '../../content/about.content';
import LandingContainer from '../ui/LandingContainer';

const AboutHero = () => {
	return (
		<>
			<LandingContainer>
				<div className="relative container space-y-16 md:space-y-32 my-32 px-0">
					<div className="space-y-5">
						<div className="mx-auto w-full lg:px-0">
							<h1 className="w-full text-center text-4xl lg:text-7xl   text-black">
								{aboutPage.hero.title}
							</h1>
						</div>
						<div>
							<div className="text-xl mx-auto mb-10 w-full text-center text-">
								{aboutPage.hero.description}
							</div>
						</div>
					</div>
				</div>
			</LandingContainer>
			<img
				alt="Team 4"
				loading="lazy"
				width={1725}
				height={1152}
				decoding="async"
				data-nimg={1}
				className="transition-opacity w-full opacity-100 -mt-20 lg:-mt-32 mb-20"
				src={aboutPage.hero.img}
				style={{ color: 'transparent' }}
			/>
		</>
	);
};

export default AboutHero;
