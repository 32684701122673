import InputText from '@/components/element/inputs/InputText';

const AffiliateProgramFilters = ({
	search,
	setSearch,
}: {
	search: string;
	setSearch: any;
}) => {
	return (
		<div className="flex items-center mt-10 space-x-4">
			<InputText
				value={search}
				setValue={setSearch as any}
				prepend={
					<i className="bi-search px-3 text-muted-foreground text-sm"></i>
				}
				placeholder="Search by Enterprise Name or Email Id"
				className="w-[250px] lg:w-[350px]"
			/>
		</div>
	);
};

export default AffiliateProgramFilters;
