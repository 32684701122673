import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import AdminCampaigns from '@/features/admin/pages/AdminCampaigns';
import AdminCustomTokens from '@/features/admin/pages/AdminCustomTokens';
import AdminDaveActions from '@/features/admin/pages/AdminDaveActions';
import AdminEnterprises from '@/features/admin/pages/AdminEnterprises';
import DashboardLayout from '@/features/dashboard/components/layout/DashboardLayout';
import { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AppError } from '@/components/layout/error/SentryErrorFallback';
import * as Sentry from '@sentry/react';
import GlobalLayout from '@/components/layout/GlobalLayout';
import AdminAffiliateProgram from '@/features/admin/pages/AdminAffiliateProgram';
import AdminCMSRoute from '@/features/admin/routes/admin-cms.route';

function App() {
	const location = useLocation();
	if (location.pathname.includes('new') || location.pathname.includes('update')) {
		return (
			<Sentry.ErrorBoundary fallback={AppError} showDialog>
				<Suspense fallback={<FullPageLoading />}>
					<Outlet />
				</Suspense>
			</Sentry.ErrorBoundary>
		);
	}
	return (
		<Sentry.ErrorBoundary fallback={AppError} showDialog>
			<DashboardLayout>
				<Suspense fallback={<FullPageLoading />}>
					<Outlet />
				</Suspense>
			</DashboardLayout>
		</Sentry.ErrorBoundary>
	);
}
export const adminRoutes = [
	{
		path: '/app/admin',
		element: (
			<GlobalLayout>
				<App />
			</GlobalLayout>
		),
		children: [
			{
				path: '/app/admin/enterprises',
				element: <AdminEnterprises />,
			},
			{
				path: '/app/admin/campaigns',
				element: <AdminCampaigns />,
			},
			{
				path: '/app/admin/custom-tokens',
				element: <AdminCustomTokens />,
			},
			{
				path: '/app/admin/dave-actions',
				element: <AdminDaveActions />,
			},
			{
				path: '/app/admin/affiliate-program',
				element: <AdminAffiliateProgram />,
			},
			{
				path: '/app/admin/cms/*',
				element: <AdminCMSRoute />,
			},
			{
				path: '/app/admin/affiliate-program',
				element: <AdminAffiliateProgram />,
			},
			{
				path: '*',
				element: <Navigate to="." />,
			},
		],
	},
	{
		path: '*',
		element: <Navigate_ />,
	},
];

function Navigate_() {
	return <Navigate to={`/app/home${window.location.search}`} />;
}
