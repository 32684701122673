import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	IAdminEnterprise,
	IEnterpriseFeatureUsage,
	IEnterpriseSimilarProjects,
} from '../../types/admin.types';
import { JSONViewer } from '@/components/element/json/JSONViewer';
import { convertToTitleCase, formatCompactNumber } from '@/utils/parsers';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import ChainBadge from '@/components/element/badges/ChainBadge';
import {
	EnterpriseLeadType,
	EnterpriseOnboardingStage,
} from '@/features/dashboard/types/enterprise.types';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { QUEST_URL } from '@/config';
import { Status } from '@/features/campaigns/types';
import { CustomTokenStatus } from '@/features/campaigns/hooks/tokens/useCreateToken';
import EditEnterpriseData from './EditEnterpriseData';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import InputText from '@/components/element/inputs/InputText';

export const ApprovalData = ({
	enterprise,
	isLoading,
	details,
	setDetails,
	updateInlineItems,
}: {
	enterprise: IAdminEnterprise;
	isLoading: boolean;
	details: any;
	setDetails: Dispatch<SetStateAction<any>>;
	updateInlineItems: () => void;
}) => {
	const [isDataEditable, setIsDataEditable] = useState<string | null>(null);

	if (isLoading) return <div>Loading...</div>;
	const approvalData = enterprise?.approvalData;
	return (
		<div className="mx-2">
			<div className="mb-10">
				<div className="font-medium mb-4 text-sm">Enterprise Data</div>
				<JSONViewer
					data={[
						{
							icon: 'building',
							label: 'Enterprise Name',
							value: (
								<div className="flex items-center py-1 max-w-[450px] truncate">
									<Avatar className="h-5 w-5 me-2">
										<AvatarImage
											src={enterprise?.enterpriseData?.logo}
											alt="@shadcn"
										/>
										<AvatarFallback
											name={enterprise?.enterpriseData?.name}
										/>
									</Avatar>
									{enterprise?.enterpriseData?.name}
								</div>
							),
						},
						{
							icon: 'file-text',
							label: 'Description',
							value: (
								<div>
									<p
										dangerouslySetInnerHTML={{
											__html: enterprise?.enterpriseData
												?.description,
										}}
									></p>
								</div>
							),
						},
						{
							icon: 'file-text',
							label: 'Onboarding Stage',
							value: (
								<Badge
									variant="secondary"
									className={
										enterprise?.enterpriseData
											?.onboardingStage ===
										EnterpriseOnboardingStage.Completed
											? 'bg-green-100'
											: ''
									}
								>
									{convertToTitleCase(
										enterprise?.enterpriseData?.onboardingStage,
									)}
								</Badge>
							),
						},
						{
							icon: 'link',
							label: 'Supported Chains',
							value: (
								<div className="flex flex-wrap gap-x-1 justify-end">
									{enterprise?.enterpriseData?.chainIds.map(
										(chainId) => (
											<ChainBadge
												key={chainId}
												chainId={chainId}
												hideName
											/>
										),
									)}
								</div>
							),
						},
						{
							icon: 'bullseye',
							label: 'Goal',
							value: (
								<Badge variant="secondary">
									{enterprise?.enterpriseData?.goal}
								</Badge>
							),
						},
						{
							icon: 'person',
							label: 'Owner Profile',
							value: (
								<Badge variant="secondary">
									{enterprise?.enterpriseData?.role}
								</Badge>
							),
						},
						{
							icon: 'shop',
							label: 'Acquisition Source',
							value: (
								<Badge variant="secondary">
									{enterprise?.enterpriseData?.source}
								</Badge>
							),
						},
						{
							icon: 'person-plus',
							label: 'Referred By',
							value: enterprise?.enterpriseData?.referredBy,
						},
						{
							icon: 'twitter-x',
							label: 'Twitter Credits',
							value: (
								<InputText
									value={
										details?.twitterCredits ||
										enterprise?.enterpriseData?.twitterCredits
									}
									placeholder="Enter Twitter Credits"
									setValue={(e) => {
										const numericValue = Number(e);
										if (Number.isNaN(numericValue)) {
											return;
										}
										setDetails((p) => ({
											...p,
											twitterCredits: numericValue,
										}));
									}}
									inputClassName="h-7 w-24 text-sm font-normal text-right"
								/>
							),
						},
						{
							icon: 'building-check',
							label: 'Lead Type',
							value: (
								<SelectLeadType
									enterprise={enterprise}
									details={details}
									setDetails={setDetails}
									updateInlineItems={updateInlineItems}
								/>
							),
						},
						{
							icon: 'coin',
							label: 'Token Status',
							value: (
								<Badge
									variant="secondary"
									className="flex justify-between cursor-pointer font-medium"
									onClick={() =>
										setIsDataEditable((prev) =>
											prev === 'tokenStatus'
												? null
												: 'tokenStatus',
										)
									}
								>
									{convertToTitleCase(
										details?.tokenStatus ||
											enterprise?.enterpriseData
												?.tokenStatus ||
											'none',
									)}
									<i className="bi-pen ms-2"></i>
								</Badge>
							),
						},
					]}
				/>

				<EditEnterpriseData
					isDataEditable={isDataEditable}
					details={details}
					setDetails={setDetails}
				/>

				<Button
					variant="secondary"
					onClick={updateInlineItems}
					className="flex justify-end my-2 ml-auto"
				>
					Save Details
				</Button>
			</div>
			<div className="">
				<div className="font-medium mb-3 text-sm">Approval Data</div>
				<JSONViewer
					data={[
						{
							icon: 'twitter',
							label: 'Official twitter handle',
							link: approvalData?.twitter?.username
								? `https://x.com/${approvalData?.twitter?.username}`
								: null,
							value:
								`${approvalData?.twitter?.username || 'Not Provided'} | ${formatCompactNumber(approvalData?.twitter?.followers || 0)} Followers` ||
								'Not Provided',
						},
						{
							icon: 'discord',
							label: 'Official discord username',
							link: approvalData?.discord?.username
								? `https://discord.com/${approvalData?.discord?.username}`
								: null,
							value: approvalData?.discord?.username || 'Not Provided',
						},
						{
							icon: 'envelope-fill',
							label: 'Verified Email',
							value: approvalData?.verifiedEmail
								? approvalData?.verifiedEmail
								: 'Nope',
						},
						{
							icon: 'twitter',
							label: 'Social twitter handle',
							link:
								enterprise?.enterpriseData?.socials?.twitter || null,
							value: `${enterprise?.enterpriseData?.socials?.twitter || 'Not Provided'}`,
						},
						{
							icon: 'discord',
							label: 'Social discord handle',
							link:
								enterprise?.enterpriseData?.socials?.discord || null,
							value: `${enterprise?.enterpriseData?.socials?.discord || 'Not Provided'}`,
						},
						{
							icon: 'globe',
							label: 'Social website',
							link:
								enterprise?.enterpriseData?.socials?.website || null,
							value: `${enterprise?.enterpriseData?.socials?.website || 'Not Provided'}`,
						},
						{
							icon: 'twitter',
							label: 'Social twitter handle',
							link:
								enterprise?.enterpriseData?.socials?.twitter || null,
							value: `${enterprise?.enterpriseData?.socials?.twitter || 'Not Provided'}`,
						},
						{
							icon: 'discord',
							label: 'Social discord handle',
							link:
								enterprise?.enterpriseData?.socials?.discord || null,
							value: `${enterprise?.enterpriseData?.socials?.discord || 'Not Provided'}`,
						},
						{
							icon: 'globe',
							label: 'Social website',
							link:
								enterprise?.enterpriseData?.socials?.website || null,
							value: `${enterprise?.enterpriseData?.socials?.website || 'Not Provided'}`,
						},
						// {
						// 	icon: 'image',
						// 	label: 'Community Announcement Screenshot',
						// 	link: approvalData?.announcementScreenshot,
						// 	value: approvalData?.announcementScreenshot
						// 		? 'Link'
						// 		: 'Not Provided',
						// },
					]}
				/>
			</div>
		</div>
	);
};

export const EnterpriseCampaigns = ({
	enterprise,
	isLoading,
}: {
	enterprise: IAdminEnterprise;
	isLoading: boolean;
}) => {
	if (isLoading) return <div>Loading...</div>;
	return (
		<div className="mx-2">
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Campaigns</div>
				<JSONViewer
					data={
						Array.isArray(enterprise?.campaigns) &&
						enterprise?.campaigns?.map((i) => ({
							label: (
								<div
									className="cursor-pointer hover:underline"
									onClick={() => {
										window.open(
											`${QUEST_URL}/quest/${i._id}`,
											'_blank',
										);
									}}
								>
									<div>{i.name}</div>
									<div className="text-xs mt-1">
										{i.status === Status.Draft
											? ''
											: `${formatCompactNumber(
													i?.pageViewUsersCount,
												)} > ${formatCompactNumber(
													i?.initiatedUsersCount,
												)} > ${formatCompactNumber(
													i?.completedUsersCount,
												)}`}
									</div>
								</div>
							),
							value: (
								<div className="flex items-center gap-3">
									<StatusBadge status={i.status} />
									<i
										className="bi bi-box-arrow-up-right text-xs cursor-pointer ms-3"
										onClick={() => {
											window.open(
												`${QUEST_URL}/quest/${i._id}`,
												'_blank',
											);
										}}
									></i>
								</div>
							),
						}))
					}
				/>
			</div>
		</div>
	);
};

export const EnterpriseFeatureUsage = ({
	enterpriseFeatureUsage,
	isLoading,
}: {
	enterpriseFeatureUsage: IEnterpriseFeatureUsage;
	isLoading: boolean;
}) => {
	if (isLoading) return <div>Loading...</div>;
	return (
		<div className="mx-2">
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Core Feature Usage</div>
				<JSONViewer
					data={[
						{
							label: 'Quests',
							value: enterpriseFeatureUsage?.quests,
						},

						{
							label: 'Loyalty Tasks',
							value: enterpriseFeatureUsage?.loyaltyTasks,
						},
						{
							label: 'Events',
							value: enterpriseFeatureUsage?.events,
						},
						{
							label: 'Total Quest Users',
							value: enterpriseFeatureUsage?.users,
						},
					]}
				/>
			</div>
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Tools Usage</div>
				<JSONViewer
					data={[
						{
							label: 'NFTS',
							value: enterpriseFeatureUsage?.nfts,
						},
						{
							label: 'Dave Actions',
							value: enterpriseFeatureUsage?.daveActions,
						},
						{
							label: 'Integrated APIs',
							value: enterpriseFeatureUsage?.apis,
						},

						{
							label: 'KOL Requests',
							value: enterpriseFeatureUsage?.kolRequests,
						},
						{
							label: 'Partnership Requests',
							value: enterpriseFeatureUsage?.partnershipRequests,
						},
						{
							label: 'Integrations',
							value: enterpriseFeatureUsage?.integrations,
						},
					]}
				/>
			</div>
		</div>
	);
};

export const SimilarEnterprises = ({
	similarEnterprises,
	isLoading,
}: {
	similarEnterprises: IEnterpriseSimilarProjects[];
	isLoading: boolean;
}) => {
	if (isLoading) return <div>Loading...</div>;
	return (
		<div className="mx-2">
			<div className="mb-10">
				<div className="font-medium mb-3 text-sm">Similar Enterprises</div>
				<JSONViewer
					data={
						similarEnterprises &&
						Array.isArray(similarEnterprises) &&
						similarEnterprises.map((i) => ({
							label: (
								<div
									className="cursor-pointer flex items-center hover:underline"
									onClick={() => {
										window.open(
											`${QUEST_URL}/project/${i._id}`,
											'_blank',
										);
									}}
								>
									<Avatar className="h-5 w-5 me-2">
										<AvatarImage src={i.logo} alt="@shadcn" />
										<AvatarFallback name={i.name} />
									</Avatar>
									<div>{i.name}</div>
								</div>
							),
							value: (
								<div className="flex items-center gap-3">
									<div>
										<StatusBadge
											status={
												i.isApproved
													? CustomTokenStatus.Approved
													: CustomTokenStatus.Rejected
											}
										/>
									</div>
									<i
										className="bi bi-box-arrow-up-right text-xs cursor-pointer ms-3"
										onClick={() => {
											window.open(
												`${QUEST_URL}/project/${i.slug}`,
												'_blank',
											);
										}}
									></i>
								</div>
							),
						}))
					}
				/>
			</div>
		</div>
	);
};

const SelectLeadType = ({ enterprise, details, setDetails, updateInlineItems }) => {
	return (
		<Select
			value={details?.leadType || enterprise?.enterpriseData?.leadType}
			onValueChange={(e) => {
				setDetails((p) => ({
					...p,
					leadType: e,
				}));
			}}
		>
			<SelectTrigger className="py-0.5 px-2 text-sm font-medium h-7">
				<SelectValue placeholder={'Select lead type'} />
			</SelectTrigger>
			<SelectContent align="end" className="w-[160px]">
				{leadTypeOptions?.map((i) => (
					<SelectItem key={i.value} value={i.value}>
						<span>{i.label}</span>
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

const leadTypeOptions = [
	{
		label: 'Enterprise',
		value: EnterpriseLeadType.Enterprise,
	},
	{
		label: 'PLG',
		value: EnterpriseLeadType.Plg,
	},
];
