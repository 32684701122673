import dayjs from 'dayjs';
import moment from 'moment';
import { useMemo } from 'react';

const useEventTimestamp = ({
	startImmediately,
	startDate,
}: {
	startImmediately: boolean;
	startDate: Date;
}) => {
	const endDate = dayjs().add(7, 'day').toDate();
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const minStartTimestamp = useMemo(() => {
		if (startDate) {
			if (
				moment.tz(startDate, timezone).format('Do MMM YYYY') ===
				moment.tz(new Date(), timezone).format('Do MMM YYYY')
			) {
				return moment.tz(new Date(), timezone).format('hh:mm a');
			}
		}
		return '';
	}, [startDate, timezone]);

	const minEndTimestamp = useMemo(() => {
		if (startImmediately) {
			if (
				moment.tz(endDate, timezone).format('Do MMM YYYY') ===
				moment.tz(new Date(), timezone).format('Do MMM YYYY')
			) {
				return moment.tz(new Date(), timezone).format('hh:mm a');
			}
		} else {
			if (
				moment.tz(startDate, timezone).format('Do MMM YYYY') ===
				moment.tz(endDate, timezone).format('Do MMM YYYY')
			) {
				return moment.tz(startDate, timezone).format('hh:mm a');
			}
		}
	}, [startImmediately, endDate, timezone, startDate]);

	return {
		minEndTimestamp,
		minStartTimestamp,
		endDate,
	};
};

export default useEventTimestamp;
