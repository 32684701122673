import { queryClient } from '@/lib/react-query';
import { createAPI } from '../services/task-apis.service';
import { useMutation } from '@tanstack/react-query';
import { isValidEndpoint } from '@/utils/parsers';
import { useConnectAPI } from './useConnectAPI';
import { ApiCredentialTypes, CreateTaskAPISteps } from '../types/task-apis.enum';
import useTestAPI from './useTestAPI';
import { IAPIFormFields } from '../types/task-apis.type';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useCurlParser } from './useCurlParser';

const useCreateTaskAPI = ({ setOpen }: { setOpen: any }) => {
	const { handleTestApi } = useTestAPI();
	const {
		formFields,
		setFormFields,
		setFormErrors,
		step,
		setStep,
		resetForm,
		setIsLoading,
		parsedData,
		setParsedData,
		evaluationErrors,
		setEvaluationErrors,
		setEvaluatedFields,
	} = useConnectAPI();

	const nextStepMap = {
		[CreateTaskAPISteps.Basic]: CreateTaskAPISteps.Curl,
		[CreateTaskAPISteps.Curl]: CreateTaskAPISteps.Payload,
		[CreateTaskAPISteps.Payload]: CreateTaskAPISteps.Testing,
		[CreateTaskAPISteps.Testing]: CreateTaskAPISteps.EvaluateResponse,
		[CreateTaskAPISteps.EvaluateResponse]: CreateTaskAPISteps.Upsert,
		[CreateTaskAPISteps.Upsert]: CreateTaskAPISteps.Upsert,
		[CreateTaskAPISteps.ReTesting]: CreateTaskAPISteps.ReTesting,
	};
	const prevStepMap = {
		[CreateTaskAPISteps.Curl]: CreateTaskAPISteps.Basic,
		[CreateTaskAPISteps.Payload]: CreateTaskAPISteps.Curl,
		[CreateTaskAPISteps.Testing]: CreateTaskAPISteps.Payload,
		[CreateTaskAPISteps.EvaluateResponse]: CreateTaskAPISteps.Testing,
		[CreateTaskAPISteps.Upsert]: CreateTaskAPISteps.EvaluateResponse,
		[CreateTaskAPISteps.ReTesting]: CreateTaskAPISteps.ReTesting,
	};

	const { handleParse } = useCurlParser(
		formFields,
		setFormFields,
		parsedData,
		setParsedData,
	);

	const handleStartOver = () => {
		setStep(CreateTaskAPISteps.Basic);
		resetForm();
		setOpen(false);
	};
	const formatPayload = (
		payload: IAPIFormFields['apiPayload'],
		constantDataFields: IAPIFormFields['apiConstantDataFields'],
	) => {
		const constantKeys = new Set(constantDataFields.map((item) => item.key));
		const tempPayload = {};
		payload.forEach((item) => {
			if (!constantKeys.has(item.key)) {
				tempPayload[item.key] = item.value;
			}
		});

		return tempPayload;
	};
	const formatCustomHeaders = (headers: IAPIFormFields['apiHeaders']) => {
		const tempHeaders: any = {};
		headers.forEach((item: any) => {
			tempHeaders[item.key] = item?.value?.trim();
		});

		return tempHeaders;
	};
	const handleClick = async (step_?: CreateTaskAPISteps) => {
		if (step_) {
			return setStep(step_);
		}
		if (!verifyFormFields(step)) return;

		if (step === CreateTaskAPISteps.Curl) {
			if (
				(formFields?.apiCredentialsType === ApiCredentialTypes.Rest &&
					formFields?.curlCommand) ||
				formFields?.apiCredentialsType === ApiCredentialTypes.GraphQl
			) {
				const parseSuccess = handleParse((error) => {
					console.log(error);
					setFormErrors((prev) => ({
						...prev,
						curlCommand: 'Error parsing cURL',
					}));
				});

				if (!parseSuccess) {
					return;
				}
			}
		}

		if (
			step === CreateTaskAPISteps.Testing ||
			step === CreateTaskAPISteps.ReTesting
		) {
			setIsLoading(true);
			setEvaluationErrors({});
			setEvaluatedFields({});

			await handleTestApi();
			return;
		}
		if (step === CreateTaskAPISteps.Basic) {
			analytics.track(TrackingEvents.TaskAPisCreateDetailAdded, {
				// isUpdate: ,
			});
		}
		if (step === CreateTaskAPISteps.Payload) {
			analytics.track(TrackingEvents.TaskAPIsCreatePayloadAdded, {
				// isUpdate: ,
			});
		}
		if (step === CreateTaskAPISteps.EvaluateResponse) {
			try {
				setIsLoading(true);
				if (
					step === CreateTaskAPISteps.EvaluateResponse &&
					Object.keys(evaluationErrors).length !== 0
				) {
					return;
				}
				await handleTestApi();

				return;
			} catch (err) {
				handleErrorMessage(err);
			} finally {
				setIsLoading(false);
			}
		}
		if (step === CreateTaskAPISteps.Upsert) {
			setIsLoading(true);
			await createTaskApiMutation.mutateAsync({
				_id: formFields._id,

				apiName: formFields.apiName,
				apiEndpoint: formFields.apiEndpoint,
				apiMethod: formFields.apiMethod?.toUpperCase(),
				apiDataPassingMethod: formFields.apiPayloadMethod?.toUpperCase(),
				apiHeaders: formFields?.isHeaderRequired
					? formatCustomHeaders(formFields.apiHeaders)
					: {},
				apiDataFields: formatPayload(
					formFields.apiPayload,
					formFields.apiConstantDataFields,
				),
				apiVerificationFor: formFields.apiVerificationAction,
				isRecurringTaskEnabled: formFields.isRecurringTasksEnabled,
				isMetricBasedTaskEnabled: formFields.isMetricBasedTaskEnabled,
				apiCredentialsType: formFields.apiCredentialsType,
				apiOutputExpressions: formFields.apiOutputExpressions,
				apiConstantDataFields: formFields.apiConstantDataFields,
				graphQlQuery: formFields.graphQlQuery,
			});
			setIsLoading(false);
		}

		setStep(nextStepMap[step]);
		setFormErrors({});
	};

	const verifyFormFields = (currentStep: CreateTaskAPISteps) => {
		const error: any = {};

		switch (currentStep) {
			case CreateTaskAPISteps.Basic:
				if (!formFields?.apiName || !formFields?.apiName.trim()) {
					error.apiName = `API name is required`;
				}
				break;
			case CreateTaskAPISteps.Curl:
				if (
					formFields?.apiCredentialsType === ApiCredentialTypes.GraphQl &&
					!formFields?.curlCommand
				) {
					error.curlCommand = `Curl command is required for GraphQL queries`;
				}
				break;
			case CreateTaskAPISteps.Payload:
				setFormFields({
					...formFields,
					apiEndpoint: formFields?.apiEndpoint?.trim(),
				});
				if (!formFields?.apiEndpoint || !formFields?.apiEndpoint?.trim()) {
					error.apiEndpoint = 'API endpoint is required';
				}
				if (
					formFields?.apiEndpoint &&
					!isValidEndpoint(formFields?.apiEndpoint?.trim())
				) {
					error.apiEndpoint = `Please enter a valid endpoint. API Endpoint should be https`;
				}
				if (formFields?.apiEndpoint) {
					if (formFields?.apiEndpoint?.includes('?')) {
						error.apiEndpoint = `API endpoint should not contain query params as they will be dynamically added based on user`;
					}
				}
				if (formFields?.isHeaderRequired) {
					formFields?.apiHeaders?.forEach((item) => {
						if (
							(!item.key || !item.key.trim()) &&
							(!item.value || !item.value.trim())
						) {
							error[item.key] = `Please enter header: key and value`;
						} else if (!item.key || !item.key.trim()) {
							error[item.key] = `Please enter the key`;
						} else if (!item.value || !item.value.trim()) {
							error[item.key] = `Please enter the value`;
						}
					});
				}
				if (formFields?.isCustomConstantPayloadRequired) {
					formFields?.apiConstantDataFields?.forEach((item) => {
						if (
							(!item.key || !item.key.trim()) &&
							(!item.value || !item.value.trim())
						) {
							error[item.key] = `Please enter header: key and value`;
						} else if (!item.key || !item.key.trim()) {
							error[item.key] = `Please enter the key`;
						} else if (!item.value) {
							error[item.key] = `Please enter the value`;
						}
					});
				}
				break;
			case CreateTaskAPISteps.Testing:
			case CreateTaskAPISteps.ReTesting:
				formFields?.apiPayload.forEach((item) => {
					if (!item.testValue) {
						error[item.key] = `${item.key} is required`;
					}
				});
				break;

			default:
				return true;
		}
		setFormErrors(error);
		return Object.keys(error).length === 0;
	};

	const createTaskApiMutation = useMutation({
		mutationFn: createAPI,
		onSuccess: async () => {
			handleStartOver();
			analytics.track(TrackingEvents.TaskAPIsCreateSuccessful, {});
			await queryClient.invalidateQueries({ queryKey: ['api-requests'] });
		},
	});

	return {
		handleClick,
		verifyFormFields,
		nextStepMap,
		prevStepMap,
		handleStartOver,
	};
};

export default useCreateTaskAPI;
