import { Checkbox } from '@/components/ui/checkbox';
import { useConnectAPI } from '../hooks/useConnectAPI';

const ApiMeta = () => {
	const { formFields, setFormFields } = useConnectAPI();
	return (
		<div className="">
			<div className="flex flex-row items-start space-x-3 mt-8">
				<Checkbox
					className="mt-1"
					checked={
						formFields?.isMetricBasedTaskEnabled &&
						!formFields?.isRecurringTasksEnabled
					}
					onCheckedChange={(checked) =>
						setFormFields((prev: any) => ({
							...prev,
							isMetricBasedTaskEnabled: !!checked,
							isRecurringTasksEnabled: false,
						}))
					}
				/>
				<div className="space-y-1 leading-none">
					<div className="text-sm font-medium">
						Does your API returns value of the action?
					</div>
					<div className="text-xs">
						You can configure dynamic XPs for tasks based on impact of
						the user action. For this API needs to send{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
							value
						</code>{' '}
						.
					</div>
				</div>
			</div>
			<div className="flex flex-row items-start space-x-3 mt-4">
				<Checkbox
					className="mt-1"
					checked={
						formFields?.isRecurringTasksEnabled &&
						!formFields?.isMetricBasedTaskEnabled
					}
					onCheckedChange={(checked) =>
						setFormFields((prev: any) => ({
							...prev,
							isRecurringTasksEnabled: !!checked,
							isMetricBasedTaskEnabled: false,
						}))
					}
				/>
				<div className="space-y-1 leading-none">
					<div className="text-sm font-medium">
						Does your API supports verification for recurring tasks?
					</div>

					<div className="text-xs">
						For recurring tasks, we will send{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
							startTimestamp
						</code>{' '}
						and{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
							endTimestamp
						</code>{' '}
						to verify user action during the period.
					</div>
				</div>
			</div>
		</div>
	);
};

export default ApiMeta;
