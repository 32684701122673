import ChainBadge from '@/components/element/badges/ChainBadge';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import QuestBannerUpdate from '../updates/QuestBannerUpdate';
import QuestMetadataUpdate from '../updates/QuestMetadataUpdate';

const QuestDetails = () => {
	const [showEdit, setShowEdit] = useState(false);
	const [showUpdateBanner, setShowUpdateBanner] = useState(false);
	const { campaign } = useCampaignReport();

	const chainId = +(
		(campaign &&
			Array.isArray(campaign?.tags) &&
			campaign?.tags?.find((tag) => tag.key === 'chainId')?.value) ||
		0
	);

	const safeHTML = DOMPurify.sanitize(campaign?.description);

	return (
		<>
			<Card className="col-span-2 relative ">
				<CardHeader className="border-b p-4">
					<div className="flex items-center justify-between">
						<CardTitle className="text-base leading-5 font-semibold text-slate-700 flex items-center ">
							Quest Details
							<div className="ms-2 cursor-pointer text-slate-500">
								<i
									className="bi-pencil-square "
									onClick={() => setShowEdit(true)}
								></i>
							</div>
						</CardTitle>
						<Badge
							variant="secondary"
							className="border py-1 text-slate-700"
						>
							<StatusBadge status={campaign?.status} />
						</Badge>
					</div>
				</CardHeader>
				<CardContent className="flex justify-between  p-4 h-100 gap-10">
					<div className="h-100 flex flex-col w-5/12">
						<div className="flex items-center text-sm  mb-6 w-full">
							<div className="w-full space-y-1">
								<div className="text-xs text-slate-500 font-medium uppercase">
									Name
								</div>
								<div className="text-sm leading-[18px] font-normal text-slate-700 max-w-[85%] truncate">
									{campaign?.name}
								</div>
							</div>
						</div>

						<div className="flex items-center text-sm  mb-6">
							<div className=" space-y-1">
								<div className="text-xs text-slate-500 font-medium uppercase">
									Description
								</div>
								<div
									className="text-sm leading-[18px] font-normal text-slate-700 line-clamp-6"
									dangerouslySetInnerHTML={{
										__html: safeHTML,
									}}
								></div>
							</div>
						</div>

						{campaign?.narrativeTexts?.length > 0 &&
							campaign.narrativeTexts[0].text && (
								<div className="flex items-center text-sm  mb-6">
									<div className=" space-y-1">
										<div className="text-xs text-slate-500 font-medium uppercase">
											Spotlight Text
										</div>
										<div className="text-sm leading-[18px] font-normal text-slate-700">
											{campaign?.narrativeTexts[0]?.text}
										</div>
									</div>
								</div>
							)}
					</div>
					<div className=" relative w-7/12">
						<div className="flex justify-between">
							<div className="flex items-center text-sm  mb-6">
								<div className=" space-y-1">
									<div className="text-xs text-slate-500 font-medium uppercase">
										Network
									</div>
									<ChainBadge
										chainId={chainId}
										fetchEnterpriseChains
										showNonEVM
										className="text-sm leading-[18px] font-normal text-slate-700"
									/>
								</div>
							</div>
							<div className="flex items-center text-sm  mb-6">
								<div className=" space-y-1">
									<div className="text-xs text-slate-500 font-medium uppercase">
										Duration
									</div>
									<div className="text-sm leading-[18px] font-normal text-slate-700">
										<i className="bi-calendar2-event me-2 text-slate-600"></i>
										{dayjs(campaign?.startDate).format(
											'DD MMM YYYY',
										)}
										&nbsp;-&nbsp;
										{campaign?.noEndTime
											? 'No Deadline'
											: dayjs(campaign?.endDate).format(
													'DD MMM YYYY',
												)}
									</div>
								</div>
							</div>
						</div>
						<div
							className="w-full aspect-[1920/1080] border rounded-lg bg-slate-50 relative"
							style={{
								backgroundPosition: `${campaign?.bannerPosition?.x}% ${campaign?.bannerPosition?.y}%`,
								backgroundImage: `url(${campaign?.banner})`,
								backgroundSize: 'cover',
							}}
						>
							<div className="absolute bottom-0 end-0 pb-4 pe-3">
								<Button
									variant="outline"
									onClick={() => setShowUpdateBanner(true)}
									size="sm"
								>
									<i className="bi-pencil-square me-2"></i> Update
									Banner
								</Button>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
			<QuestMetadataUpdate open={showEdit} setOpen={setShowEdit} />
			<QuestBannerUpdate
				open={showUpdateBanner}
				setOpen={setShowUpdateBanner}
			/>
		</>
	);
};

export default QuestDetails;
