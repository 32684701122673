import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import useCampaignRewards from '@/features/campaigns/hooks/report/useCampaignRewards';
import { RewardCard } from '../rewardCard/RewardCard';

const RewardDetails = () => {
	const {
		rewardDetails,
		isLoading,
		currentReward,
		handleNext,
		handlePrev,
		currentIndex,
		tooltipText,
		campaignStatus,
	} = useCampaignRewards();

	if (!rewardDetails || rewardDetails.length === 0) {
		return (
			<Card className="h-full">
				<CardHeader className="border-b py-4">
					<div className="flex items-center justify-between">
						<CardTitle className="text-base leading-5 font-semibold text-slate-700">
							Reward Details
						</CardTitle>
						<Badge variant="secondary" className="py-1 text-slate-700">
							0 Reward(s)
						</Badge>
					</div>
				</CardHeader>
				{isLoading ? (
					<div className="space-y-4 mt-5">
						{[...Array(6).keys()].map((_, index) => (
							<div key={index} className="px-4 ">
								<Skeleton className="h-7 w-full" />
							</div>
						))}
					</div>
				) : (
					<CardContent className="flex items-center justify-center h-full px-10 text-center">
						<div className="text-sm text-muted-foreground mb-14">
							No rewards have been set up for this quest.
						</div>
					</CardContent>
				)}
			</Card>
		);
	}

	return (
		<Card className="min-h-full flex flex-col">
			<CardHeader className="border-b p-4">
				<div className="flex items-center justify-between">
					<CardTitle className="text-base leading-5 font-semibold text-slate-700">
						Reward Details
					</CardTitle>
					<div className="flex items-center gap-1">
						{rewardDetails.length > 1 && (
							<div className="space-x-1">
								<Button
									size="icon"
									variant="outline"
									className="size-[26px]"
									onClick={handlePrev}
									disabled={currentIndex === 0}
								>
									<i className="bi-chevron-left" />
								</Button>
								<Button
									size="icon"
									variant="outline"
									className="size-[26px]"
									onClick={handleNext}
									disabled={
										currentIndex === rewardDetails.length - 1
									}
								>
									<i className="bi-chevron-right" />
								</Button>
							</div>
						)}
						<Badge variant="secondary" className="py-1 text-slate-700">
							{rewardDetails.length} Reward(s)
						</Badge>
					</div>
				</div>
			</CardHeader>
			<CardContent className="relative shadow-none border-none p-4 h-full">
				{currentReward && (
					<RewardCard
						reward={currentReward}
						tooltipText={tooltipText}
						campaignStatus={campaignStatus}
					/>
				)}
			</CardContent>
		</Card>
	);
};

export default RewardDetails;
