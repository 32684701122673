import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
} from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import SeparatorWithText from '@/components/ui/seprator-with-text';
import { ITask } from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import TaskInputs from '../../../create-campaign/tasks/components/Tasks/params/inputs/TaskInputs';
import { useEditLiveTask } from '@/features/campaigns/hooks/report/useEditLiveTask';
import Spinner from '@/components/element/loading/Spinner';
import { toast } from 'sonner';
import { uploadFile } from '@/services/utility.service';
import { useRef, useState } from 'react';
import { links } from '@/config/links';
import { EditLiveTaskSteps } from '@/features/campaigns/types/tasks.enums';
import TaskFeatures from './TaskFeatures';

const TaskAdminInputsUpdate = ({
	open,
	setOpen,
	task: task_,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	task: ITask;
}) => {
	const {
		errors,
		setErrors,
		setTask,
		editTaskAdminInput,
		template,
		updateTask,
		onClose,
		isLoading,
		task,
		setIsDirty,
		step,
		setStep,
	} = useEditLiveTask(task_, setOpen);

	const isSlidesAddedForTask = (task as ITask).slides?.length > 0;

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[635px] px-0 p-0 overflow-y-visible">
				<DialogHeader className="border-b p-3 px-4 flex flex-row justify-between items-center">
					<div className="flex space-x-2 items-center text-black font-medium">
						<i className="bi-plus-circle-dotted "></i>
						<div className="text-sm text">Updating Task</div>
					</div>
					<div className="flex items-center">
						<div
							className="text-xs hover:underline cursor-pointer"
							onClick={() => {
								window.open(links.docs.updatingTasks, '_blank');
							}}
						>
							Learn more
						</div>
						<Separator
							orientation="vertical"
							className="ml-4 mr-8 h-[20px]"
						/>
						{/* <i
							className="bi-x text-xl text-muted-foreground cursor-pointer"
							onClick={onClose}
						></i> */}
					</div>
				</DialogHeader>
				<div className=" px-4">
					<div className="text-xs  text-muted-foreground mt-1 mb-6">
						{template?.description}
					</div>
					{step === EditLiveTaskSteps.TaskParams && (
						<TaskParamsStep
							task={task as ITask}
							template={template}
							setTask={setTask}
							setIsDirty={setIsDirty}
							errors={errors}
							editTaskAdminInput={editTaskAdminInput}
							setErrors={setErrors}
							task_={task_}
						/>
					)}
					{step === EditLiveTaskSteps.TaskFeatures && (
						<TaskFeatures task={task as ITask} setTask={setTask} />
					)}
				</div>
				<DialogFooter className=" border-t py-3 px-4">
					<Button
						onClick={updateTask}
						disabled={isLoading}
						className="px-10"
					>
						{isLoading && <Spinner className="me-2" />}
						<span>
							{step === EditLiveTaskSteps.TaskParams
								? 'Next'
								: 'Update Task'}
						</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default TaskAdminInputsUpdate;

const TaskTitleAndLogo = ({ task, template, setTask, setIsDirty, errors }) => {
	const inputRef = useRef<HTMLInputElement>();

	const [fileLink, setFileLink] = useState<string | null>(
		task?.logo?.type === 'image' ? task?.logo?.value : null,
	);

	const uploadTaskIcon = async (file: any) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					uploadFile(file).then((res) => {
						resolve(res);
						setTask((prev) => ({
							...prev,
							logo: {
								type: 'image',
								value: res,
							},
						}));
					}),
				]),
				{
					loading: 'Uploading icon...',
					success: 'Task icon uploaded successfully',
					error: 'Failed to upload',
				},
			);
		});
	};

	return (
		<div className="flex item-end space-x-5 ">
			<div className="h-14 w-14 rounded bg-slate-100 py-1  flex items-center justify-center relative border">
				<div
					className="absolute top-0 cursor-pointer end-0 h-4 w-4 bg-slate-400 flex items-center justify-center -m-1 rounded-full"
					onClick={() => {
						inputRef.current.click();
					}}
				>
					<i className="bi-pencil-fill text-[8px] text-white cursor-pointer"></i>
				</div>
				{fileLink ? (
					<img src={fileLink} className="h-12 w-12 rounded" />
				) : (
					template.logo?.type === 'icon' && (
						<i
							className={cn(
								'text-4xl',
								template?.logo?.value,
								template?.color,
							)}
						></i>
					)
				)}
			</div>
			<InputText
				className="flex-grow"
				label="Task Title"
				placeholder="Add a title"
				value={task?.description}
				setValue={(e) => {
					setIsDirty(true);
					setTask({ ...task, description: e });
				}}
				error={errors?.description}
				errorText={errors?.description}
			/>
			<input
				type="file"
				className="absolute top-0 w-0 -z-1 opacity-0"
				ref={inputRef}
				onChange={(e) => {
					const file = e.target.files[0];
					setFileLink(URL.createObjectURL(inputRef.current.files[0]));
					uploadTaskIcon(file);
				}}
				accept={'image/*'}
			/>
		</div>
	);
};

const TaskParamsStep = ({
	task,
	template,
	setTask,
	setIsDirty,
	errors,
	editTaskAdminInput,
	setErrors,
	task_,
}: {
	task: ITask;
	template: any;
	setTask: any;
	setIsDirty: any;
	errors: any;
	editTaskAdminInput: any;
	setErrors: any;
	task_: any;
}) => {
	return (
		<div>
			<TaskTitleAndLogo
				task={task}
				template={template}
				setTask={setTask}
				setIsDirty={setIsDirty}
				errors={errors}
			/>
			<div className="min-h-[420px]">
				{task?.adminInputs?.length === 0 &&
					!template?.isCustomInitiationURLAllowed &&
					!template?.featureApplicability?.recurrence &&
					!template?.featureApplicability?.maxFailedCount && (
						<div className="mt-10">
							<div className="text-sm text-gray-500">
								No parameters are required for this task.
							</div>
						</div>
					)}
				{(task?.adminInputs?.length > 0 ||
					template?.isCustomInitiationURLAllowed) && (
					<div className="mt-10">
						<SeparatorWithText
							align="start"
							text="Task Parameters"
							className="mb-5"
						/>
						<div className={`grid py-2 grid-cols-12  gap-6 gap-y-8`}>
							{task.adminInputs
								?.filter((i) => !i.hide)
								.map((i) => (
									<div className={cn(`${i.size}`)}>
										<TaskInputs
											adminInput={i}
											adminInputs={task.adminInputs}
											templateFamily={task_.templateFamily}
											templateType={task_.templateType}
											description={task.description}
											taskId={task_._id}
											error={false}
											editTaskAdminInput={editTaskAdminInput}
											setErrors={(_taskId, key, value) => {
												setErrors((prev: any) => ({
													...prev,
													adminInputs: {
														...prev.adminInputs,
														[key]: value,
													},
												}));
											}}
										/>
									</div>
								))}
							{template?.isCustomInitiationURLAllowed && (
								<div className="col-span-12 mt-4">
									<InputText
										value={task.customInitiationURL}
										setValue={(e) => {
											setTask((prev) => ({
												...prev,
												customInitiationURL: e,
											}));
										}}
										label="Initiation URL (optional)"
										placeholder="https://www.example.com"
										subLabel="Add link where users will be redirected to start the task."
										subLabelClassName="font-normal text-xs mt-1"
										error={!!errors?.customInitiationURL}
										errorText={errors?.customInitiationURL}
									/>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
