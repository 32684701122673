import { Dialog, DialogContent, DialogFooter } from '@/components/ui/dialog';
import ContractProxy from './ContractProxy';
import ContractDetails from './ContractDetails';
import { useCreateContract } from '../../hooks/contracts/useCreateContract';
import ConfirmContractABI from './ConfirmContractABI';
import { Button } from '@/components/ui/button';
import Spinner from '@/components/element/loading/Spinner';

const AddContractDialog = ({
	open,
	setOpen,
	selectedContract,
	onSuccess,
	chainId,
}: {
	open: boolean;
	setOpen: any;
	selectedContract?: any;
	onSuccess?: any;
	chainId?: number;
}) => {
	const {
		step,
		details,
		handleBack,
		setDetails,
		errors,
		handleNext,
		isLoading,
		fetchStatus,
	} = useCreateContract(open, setOpen, selectedContract, onSuccess, chainId);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:min-w-[525px] sm:rounded-none overflow-visible">
				{step === 'details' && (
					<ContractDetails
						handleNext={handleNext}
						details={details}
						setDetails={setDetails}
						errors={errors}
						chainId={chainId}
					/>
				)}
				{step === 'fetching' && (
					<ContractProxy
						details={details}
						setDetails={setDetails}
						handleNext={handleNext}
						isLoading={isLoading}
						fetchStatus={fetchStatus}
					/>
				)}
				{step === 'confirm' && (
					<ConfirmContractABI
						details={details}
						setDetails={setDetails}
						handleNext={handleNext}
						errors={errors}
					/>
				)}
				<DialogFooter className="mt-5">
					{step === 'details' ? (
						<Button variant="outline" onClick={() => setOpen(false)}>
							Cancel
						</Button>
					) : (
						<Button variant="outline" onClick={() => handleBack()}>
							Back
						</Button>
					)}
					<Button
						onClick={handleNext}
						disabled={
							isLoading.checkingProxy ||
							isLoading.fetchingABI ||
							isLoading.creatingContract
						}
					>
						{isLoading.creatingContract && <Spinner className="me-2" />}
						<span>
							{step === 'confirm'
								? details?._id
									? 'Update Contract'
									: 'Add Contract'
								: 'Next'}
						</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AddContractDialog;
