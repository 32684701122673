import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { loginWithEmailAndPassword } from '../services/auth.service';
import { useAuth } from '@/hooks/useAuth';
import * as z from 'zod';
import { API_URL } from '@/config';
import { ErrorTypes, handleErrorMessageWithCode } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { getUtmParams, removeUtmParams } from '@/lib/utils';

const userAuthSchema = z.object({
	email: z.string().email({ message: 'Invalid email address' }),
	password: z
		.string()
		.min(8, { message: 'Password must be at least 8 characters long' }),
});
type FormData = z.infer<typeof userAuthSchema>;

export const useLogin = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		resolver: zodResolver(userAuthSchema),
	});
	const navigate = useNavigate();
	const { login } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDiscordLoading, setIsDiscordLoading] = useState(false);
	const [isGoogleLoading, setIsGoogleLoading] = useState(false);

	async function onSubmit(data: FormData) {
		setIsLoading(true);
		try {
			const params = getUtmParams();
			await loginWithEmailAndPassword({
				email: data.email.toLowerCase(),
				password: data.password,
				...params,
			});
			const account = await login(data.email, data.password);
			analytics.track(TrackingEvents.Login, {
				loginMethod: 'Email',
				email: data.email,
				userId: account._id,
			});
			analytics.identify(account._id, {
				email: account.email,
				name: account.name,
				enterpriseId: account?.enterpriseId,
				enterpriseName: account?.enterpriseName,
			});
			setIsLoading(false);
			removeUtmParams();
			navigate('/app/home');
		} catch (err) {
			console.log(err);
			setIsLoading(false);
			handleErrorMessageWithCode(err, [
				{
					code: ErrorTypes.ResourceMissing,
					message:
						"We couldn't find this email. If you are logging in for the first time, please register first.",
				},
				{
					code: ErrorTypes.IncorrectPassword,
					message: 'Incorrect password, please try again',
				},
			]);
		}
	}

	async function loginWithDiscord() {
		setIsDiscordLoading(true);
		window.location.href = `${API_URL}/api/v1/auth/discord`;
	}

	async function loginWithGoogle() {
		setIsGoogleLoading(true);
		window.location.href = `${API_URL}/api/v1/auth/google`;
	}

	return {
		handleSubmit,
		register,
		errors,
		isLoading,
		onSubmit,
		loginWithDiscord,
		loginWithGoogle,
		isDiscordLoading,
		isGoogleLoading,
	};
};
