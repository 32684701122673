import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { IEvent, IEventReport } from '../../types/events.type';
import { ICampaign } from '@/features/campaigns/types';
import { useParams } from 'react-router-dom';
import { getEvent, getEventReport } from '../../services/event.service';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

enum ReportTabs {
	Overview = 'overview',
	Leaderboard = 'leaderboard',
	Analytics = 'analytics',
	Channels = 'channels',
}

export const useEventReport = () => {
	const { id } = useParams();

	const [selectedTab, setSelectedTab] = useState<ReportTabs>(ReportTabs.Overview);
	const [recentEvents, setRecentEvents] = useLocalStorage('recent-events', '');

	const { data, isLoading } = useQuery<{
		event: IEvent;
		campaigns: ICampaign[];
	}>({
		queryKey: ['event', id],
		queryFn: () => getEvent(id),
		enabled: !!id,
	});

	const { data: eventReport, isLoading: isReportLoading } = useQuery<IEventReport>(
		{
			queryKey: ['event', id, 'report'],
			queryFn: () => getEventReport(id),
			enabled: !!id,
		},
	);

	const metrics = useMemo(() => {
		if (!data) return;
		const { event, campaigns } = data;
		console.log('campaign', campaigns);
		const totalParticipants = campaigns.reduce(
			(acc, campaign) => acc + campaign.initiatedUsersCount,
			0,
		);
		const totalCompletions = campaigns.reduce(
			(acc, campaign) => acc + campaign.completedUsersCount,
			0,
		);
		const totalCompletionRate = totalParticipants
			? Number((totalCompletions / totalParticipants) * 100).toFixed(2)
			: 0;
		const uniqueUsersInitiations = event?.funnel?.logins?.uniqueUsers || 0;
		const uniqueUsersCompletions = 0;

		const totalPageViews = campaigns.reduce(
			(acc, campaign) => acc + campaign.pageViewUsersCount,
			0,
		);
		const totalBounceRate = totalPageViews
			? Number((1 - totalParticipants / totalPageViews) * 100).toFixed(2)
			: 0;
		const metrics = {
			totalParticipants,
			totalCompletions,
			totalCompletionRate,
			uniqueUsersCompletions,
			uniqueUsersInitiations,
			totalBounceRate,
		};
		if (eventReport) {
			metrics.uniqueUsersCompletions = eventReport.uniqueUsersCompletions;
		}
		return metrics;
	}, [data, eventReport]);

	useEffect(() => {
		if (!data) return;
		analytics.track(TrackingEvents.EventViewed, {
			eventId: data.event._id,
			eventTitle: data.event.title,
			eventStatus: data.event.status,
		});
		const recentEventsArray = recentEvents.split(',');
		if (!recentEventsArray.includes(id)) {
			recentEventsArray.push(id);
			setRecentEvents(recentEventsArray.join(','));
		}
	}, [data]);

	return {
		selectedTab,
		setSelectedTab,
		event: data?.event,
		campaigns: data?.campaigns,
		isLoading,
		metrics,
		eventReport,
		isReportLoading,
	};
};
