import InputText from '@/components/element/inputs/InputText';
import { useOnboarding } from '../hooks/useOnboardingV2';
import { Button } from '@/components/ui/button';
import logo from '@/assets/logo.svg';
import AuthLayout from '@/components/layout/auth/AuthLayout';
import { useEffect } from 'react';
import InputSelect from '@/components/element/inputs/InputSelect';
import { tokenStatus } from '../types/onboarding-options';
import { EnterpriseTokenStatus } from '@/features/dashboard/types/enterprise.types';

export default function OnboardingUser() {
	const {
		community,
		setCommunity,
		isLoading,
		errors,
		handleCommunitySetup,
		setErrors,
	} = useOnboarding();

	useEffect(() => {
		setErrors({
			name: false,
			twitter: false,
		});
	}, [community.name, community.twitter, setErrors]);
	return (
		<AuthLayout>
			<div className="mx-auto flex w--full flex-col justify-center items-center sm:w-[420px] px-8 lg:px-0 text-center">
				<div className="space-y-8 w-full">
					<div className="mb-5 pb-5 text-center flex flex-col items-center">
						<img src={logo} className="h-10 mb-5" />
						<h3 className="text-lg font-medium">
							Setup your community profile
						</h3>
						<p className="text-muted-foreground mt-1 text-sm px-10">
							Please provide the following details to setup your
							community profile
						</p>
					</div>
					<InputText
						label="Project Name"
						required={true}
						placeholder="Uniswap"
						value={community.name}
						setValue={(e) =>
							setCommunity((p) => ({
								...p,
								name: e,
							}))
						}
						error={errors.name}
						errorText="Please enter your project name"
						className="text-left"
					/>
					<InputText
						label="Project Twitter"
						required={true}
						placeholder="intractCampaign"
						prepend={
							<div className="ps-3 pe-2 flex items-center text-xs">
								<i className="bi bi-twitter text-twitter pe-2"></i>
								<span className="text-muted-foreground font-medium">
									https://twitter.com/
								</span>
							</div>
						}
						value={community.twitter}
						setValue={(e) =>
							setCommunity((p) => ({
								...p,
								twitter: e,
							}))
						}
						errorText="Please enter your twitter username"
						error={errors.twitter}
						className="text-left"
					/>
					<InputSelect
						label="What is your token status?"
						placeholder="Select token status"
						options={tokenStatus}
						value={community.tokenStatus}
						setValue={(e) =>
							setCommunity((p) => ({
								...p,
								tokenStatus: e,
							}))
						}
						errorText="Please select a category"
						className="text-left"
						placeholderClassName="!text-muted-foreground"
					/>
					{community.tokenStatus === EnterpriseTokenStatus.Listed ? (
						<div className="flex gap-4 w-full">
							<InputText
								label="Chain Id"
								placeholder="1234"
								value={community?.tokenDetails?.tokenChainId}
								setValue={(e) => {
									const numericValue = Number(e);
									if (isNaN(numericValue)) {
										// If the value is not a number, return early
										return;
									}
									setCommunity((p) => ({
										...p,
										tokenDetails: {
											...p.tokenDetails,
											tokenChainId: numericValue,
										},
									}));
								}}
								className="text-left w-[20%]"
							/>
							<InputText
								label="Token Address"
								placeholder="0xb851220202C019d1645CB9f75dcc5ef7a66aaca9"
								value={community?.tokenDetails?.tokenAddress}
								setValue={(e) =>
									setCommunity((p) => ({
										...p,
										tokenDetails: {
											...p.tokenDetails,
											tokenAddress: e,
										},
									}))
								}
								className="text-left w-[80%]"
							/>
						</div>
					) : null}

					<div className="mt-5 flex gap-3">
						<Button
							onClick={handleCommunitySetup}
							disabled={isLoading}
							className="flex-grow"
						>
							{isLoading ? (
								<span>Loading...</span>
							) : (
								<span>Continue</span>
							)}
						</Button>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}
