export const RewardDetailRow = ({ label, value }) => {
	if (value === null || value === undefined) return null;

	return (
		<div className="flex font-medium justify-between items-center">
			<div className="text-xs text-slate-500 font-medium uppercase">
				{label}
			</div>
			<div className="text-sm leading-[18px] font-normal text-slate-700 max-w-[50%] truncate">
				{value}
			</div>
		</div>
	);
};
