import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import '@/styles/global.css';
import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from './config/index.ts';

const isProduction = process.env.NODE_ENV === 'production';

console.log('isProduction', isProduction);
console.log('env', process.env.NODE_ENV);

if (isProduction) {
	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
