import {
	IQuestPOWSubmissions,
	ITask,
	TaskKeys,
	TemplateType,
} from '@/features/campaigns/types';
import { Media } from '@/components/element/media/Media';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { getFileType } from '@/utils/parsers';
import { Button } from '@/components/ui/button';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

export const SubmissionItem = ({
	task,
	submission,
}: {
	task: ITask;
	submission: IQuestPOWSubmissions;
}) => {
	const fileType = getFileType(submission?.userInputs?.['ANSWER']);
	const adminSelectedFileType = task?.adminInputs?.find(
		(i) => i.key === TaskKeys.FileType,
	)?.value;
	console.log('adminSelectedFileType', adminSelectedFileType);
	if (!task || !submission) return null;
	return (
		<div className="rounded p-5 w-full ">
			<div className="flex items-center text-sm mb-10">
				<Avatar className="h-5 w-5 me-2">
					<AvatarImage src={submission?.avatar} alt="@intract" />
					<AvatarFallback name={submission?.avatar} />
				</Avatar>
				<div>{submission?.name}</div>
			</div>
			<div className="text-sm text-muted-foreground mb-2">
				{task?.description}
			</div>
			{task.templateType === TemplateType.FileUpload ? (
				fileType === 'image' ||
				fileType === 'video' ||
				fileType === 'gif' ? (
					<Media
						src={submission?.userInputs?.['ANSWER']}
						className="rounded max-h-[400px]"
						key={submission?._id}
						controls
					/>
				) : adminSelectedFileType === 'pdf' ? (
					<>
						<Button
							onClick={() => {
								window.open(
									submission?.userInputs?.['ANSWER'],
									'_blank',
								);
							}}
							variant="outline"
							size="sm"
							className="mb-5"
						>
							<i className="bi-link-45deg me-1"></i>
							Submission Link
						</Button>
						<iframe
							src={submission?.userInputs?.['ANSWER']}
							width="100%"
							className="rounded"
							height="450"
						></iframe>
					</>
				) : adminSelectedFileType === 'txt' ? (
					<div className="">
						<Button
							onClick={() => {
								window.open(
									submission?.userInputs?.['ANSWER'],
									'_blank',
								);
							}}
							variant="outline"
							size="sm"
							className="mb-5"
						>
							<i className="bi-link-45deg me-1"></i>
							Submission Link
						</Button>
					</div>
				) : (
					<TooltipWrapper
						tooltip={submission?.userInputs?.['ANSWER']}
						align="end"
					>
						<div className="max-w-[20rem] truncate">
							{submission?.userInputs?.['ANSWER']}
						</div>
					</TooltipWrapper>
				)
			) : (
				<TooltipWrapper
					tooltip={submission?.userInputs?.['ANSWER']}
					align="end"
				>
					<div className="max-w-[20rem] truncate">
						{submission?.userInputs?.['ANSWER']}
					</div>
				</TooltipWrapper>
			)}
		</div>
	);
};
