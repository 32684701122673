/* eslint-disable no-mixed-spaces-and-tabs */
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { z } from 'zod';
import { Avatar } from '@/components/ui/avatar';
import { AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { formatNumberWithCommas, truncateString } from '@/utils/parsers';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { ILeaderboardUsers } from '../../types/leaderboard.types';
import { Button } from '@/components/ui/button';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.

const columnHelper = createColumnHelper<ILeaderboardUsers>();

export const columns = (
	showCampaignXps: boolean,
	setShowTaskInfo: (showTaskInfo: boolean) => void,
	setSelectedUserId: (selectedUserId: string) => void,
	showIsBlacklisted?: boolean,
	showHumanProbability?: boolean,
	showReferredUsersCount?: boolean,
): ColumnDef<ILeaderboardUsers>[] =>
	[
		columnHelper.accessor('rank', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Rank"
					className=" w-[55px]"
				/>
			),
			cell: ({ row }) => <div className="">{row.getValue('rank')}</div>,
			enableSorting: true,
			enableHiding: false,
		}),

		{
			accessorKey: 'username',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					className=" min-w-[200px] max-w-[200px] w-[200px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center ">
					<Avatar className="h-7 w-7 me-2">
						<AvatarImage src={row.original.avatar} alt="username" />
						<AvatarFallback name={row.original.username} />
					</Avatar>
					{truncateString(row.original.username, 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'totalXp',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="XPs"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{formatNumberWithCommas(row.getValue('totalXp'))}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		...(showCampaignXps
			? [
					{
						accessorKey: 'campaignXp',
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title="Campaign XPs"
								className=" w-[100px]"
							/>
						),
						cell: ({ row }) => (
							<div className="flex items-center">
								{formatNumberWithCommas(row.getValue('campaignXp'))}
							</div>
						),
						enableSorting: false,
						enableHiding: false,
					},
				]
			: []),
		...(showReferredUsersCount
			? [
					columnHelper.accessor('referredUsersCount', {
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title="Referred Users"
							/>
						),
						cell: ({ row }) => (
							<div className="flex items-center">
								{row.getValue('referredUsersCount')}
							</div>
						),
						enableSorting: false,
						enableHiding: false,
					}),
				]
			: []),
		columnHelper.accessor('twitter', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Twitter"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{truncateString(row.getValue('twitter'), 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		}),
		columnHelper.accessor('discord', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Discord"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{truncateString(row.getValue('discord'), 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		}),
		...(showIsBlacklisted
			? [
					columnHelper.accessor('isBlacklisted', {
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title="Sybil"
								className=" w-[50px]"
							/>
						),
						cell: ({ row }) => (
							<div className="flex items-center">
								{row.getValue('isBlacklisted') ? 'Yes' : 'No'}
							</div>
						),
						enableSorting: false,
						enableHiding: false,
					}),
				]
			: []),
		...(showHumanProbability
			? [
					columnHelper.accessor('humanProbability', {
						header: ({ column }) => (
							<DataTableColumnHeader
								column={column}
								title="Humanity"
								className=" w-[80px]"
							/>
						),
						cell: ({ getValue }) =>
							!getValue() && getValue() !== 0 ? (
								<div className="flex items-center">-</div>
							) : (
								<div className="flex items-center">
									{getValue()}%
								</div>
							),
						enableSorting: false,
						enableHiding: false,
					}),
				]
			: []),
		{
			id: 'actions',
			cell: ({ row }) => {
				const hasTaskPrefix = Object.keys(row.original).some((key) =>
					key.startsWith('task_'),
				);

				return hasTaskPrefix ? (
					<div className="flex items-center gap-2">
						<Button
							variant="ghost"
							onClick={(e) => {
								e.stopPropagation();
								setShowTaskInfo(true);
								setSelectedUserId(row.original._id);
							}}
							className="flex items-center text-slate-700"
						>
							<i className="bi-info-circle-fill me-1 text-slate-700 mt-0.5"></i>
							Task Info
						</Button>
					</div>
				) : null;
			},
		},
	].filter((i) => i);
