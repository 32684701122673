import { Dispatch, SetStateAction, useState } from 'react';
import { ErrorsState, IAPIFormFields } from '../types/task-apis.type';
import { CreateTaskAPISteps, EvaluationFields } from '../types/task-apis.enum';

interface ExpressionValueState {
	[key: string]: any;
}

const useEvaluateExpressions = ({
	formFields,
	setFormFields,
	evaluationErrors: errors,
	setEvaluationErrors: setErrors,
	evaluatedFields,
	setEvaluatedFields,
	setStep,
}: {
	formFields: IAPIFormFields;
	setFormFields: Dispatch<SetStateAction<IAPIFormFields>>;
	evaluationErrors: ErrorsState;
	setEvaluationErrors: Dispatch<SetStateAction<ErrorsState>>;
	evaluatedFields: Record<string, boolean>;
	setEvaluatedFields: Dispatch<SetStateAction<Record<string, boolean>>>;
	setStep: Dispatch<SetStateAction<CreateTaskAPISteps>>;
}) => {
	const [expressionValue, setExpressionValue] = useState<ExpressionValueState>({});
	const [openAccordion, setOpenAccordion] = useState('result');

	const evaluationFields = [
		{
			label: EvaluationFields.DataResult,
			valueFrom: formFields?.apiOutputExpressions?.result?.expression || '',
			evaluationType: 'boolean',
			show: true,
			required: true,
		},
		{
			label: EvaluationFields.ErrorCode,
			valueFrom: formFields?.apiOutputExpressions?.code?.expression || '',
			evaluationType: 'any',
			show: true,
			required: false,
		},
		{
			label: EvaluationFields.ErrorMessage,
			valueFrom: formFields?.apiOutputExpressions?.message?.expression || '',
			evaluationType: 'any',
			show: true,
			required: false,
		},
		{
			label: EvaluationFields.DataMetric,
			valueFrom: formFields?.apiOutputExpressions?.metric?.expression || '',
			evaluationType: 'number',
			show: formFields.isMetricBasedTaskEnabled,
			required: true,
		},
		{
			label: EvaluationFields.DataMetricDataType,
			valueFrom:
				formFields?.apiOutputExpressions?.metricDataType?.expression || '',
			evaluationType: ['INTEGER', 'DOUBLE'],
			show: formFields?.isMetricBasedTaskEnabled,
			required: true,
		},
	];

	const handleAccordionChange = (value: string) => {
		setOpenAccordion((prevValue) => (prevValue === value ? null : value));
	};
	const evaluateExpression = (expression, response) => {
		try {
			const func = new Function('response', expression);
			return func(response);
		} catch (error) {
			console.log(error);
			return undefined;
		}
	};

	const handleEvaluate = (
		expression: string,
		evaluateFor: string,
		evaluationType: string | string[],
	) => {
		const result = evaluateExpression(expression, formFields?.responseSchema);
		setExpressionValue((prev) => ({
			...prev,
			[evaluateFor]: result === undefined ? 'Invalid expression' : result,
		}));

		setErrors((prevErrors) => {
			const newErrors = { ...prevErrors };
			delete newErrors[evaluateFor];

			switch (evaluateFor) {
				case EvaluationFields.DataResult:
					if (typeof result !== 'boolean') {
						newErrors[evaluateFor] =
							`Error: Expected boolean for ${evaluateFor}`;
					}
					break;
				case EvaluationFields.ErrorCode:
				case EvaluationFields.ErrorMessage:
					if (result === undefined) {
						newErrors[evaluateFor] =
							`Error: Result is undefined for ${evaluateFor}`;
					}
					break;
				case EvaluationFields.DataMetric:
					if (typeof result !== 'number') {
						newErrors[evaluateFor] =
							`Error: Expected Number() for ${evaluateFor}`;
					}
					break;
				case EvaluationFields.DataMetricDataType:
					if (
						!Array.isArray(evaluationType) ||
						!evaluationType.includes(result)
					) {
						newErrors[evaluateFor] =
							`Error: Invalid data type for ${evaluateFor}. Expected ${Array.isArray(evaluationType) ? evaluationType.join(' or ') : evaluationType}`;
					}
					break;
			}
			Object.keys(newErrors).length > 0 &&
				setStep(CreateTaskAPISteps.EvaluateResponse);
			return newErrors;
		});
	};

	const handleMetricDataTypeChange = (dataType: string) => {
		setFormFields({
			...formFields,
			apiOutputExpressions: {
				...formFields?.apiOutputExpressions,
				metricDataType: {
					expression: `return '${dataType}' `,
				},
			},
		});
		setEvaluatedFields((prev) => ({
			...prev,
			[EvaluationFields.DataMetricDataType]: true,
		}));
	};
	const handleExpressionValueChange = (attribute: string, value: string) => {
		setStep(CreateTaskAPISteps.EvaluateResponse);
		setFormFields({
			...formFields,
			apiOutputExpressions: {
				...formFields?.apiOutputExpressions,
				[attribute]: {
					expression: value,
				},
			},
		});
		setErrors((prev) => ({
			...prev,
			[attribute]: '',
		}));
		setExpressionValue((prev) => ({
			...prev,
			[attribute]: '',
		}));
		// Remove the field from evaluatedFields when the expression is changed
		setEvaluatedFields((prev) => {
			const newEvaluatedFields = { ...prev };
			delete newEvaluatedFields[attribute];
			return newEvaluatedFields;
		});
	};
	const handleEvaluateWrapper = (
		valueFrom: string,
		label: string,
		evaluationType: string | string[],
	) => {
		handleEvaluate(valueFrom, label, evaluationType);
		setEvaluatedFields((prev) => ({ ...prev, [label]: true }));
	};

	return {
		expressionValue,
		openAccordion,
		errors,
		evaluationFields,
		handleAccordionChange,
		handleEvaluate,
		handleMetricDataTypeChange,
		handleExpressionValueChange,
		evaluatedFields,
		handleEvaluateWrapper,
	};
};

export default useEvaluateExpressions;
