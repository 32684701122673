import { Label } from '@/components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { useEffect } from 'react';
import { useConnectAPI } from '../hooks/useConnectAPI';
import InputText from '@/components/element/inputs/InputText';
import {
	ApiCredentialTypes,
	ApiMethod,
	ApiVerification,
	DataPassingMethod,
} from '../types/task-apis.enum';
import { cn } from '@/lib/utils';
import ApiMeta from './ApiMeta';

export const ApiDetailsV2 = () => {
	const { formFields, setFormFields, formErrors, setFormErrors, setParsedData } =
		useConnectAPI();

	useEffect(() => {
		setFormErrors((prev: any) => ({
			...prev,
			apiEndpoint: '',
			apiName: '',
		}));
	}, [formFields.apiEndpoint, formFields.apiName, setFormErrors]);

	return (
		<div className="mt-3 mb-6">
			<div className="grid grid-cols-3 gap-4">
				<InputText
					label="API Name"
					required
					placeholder="Verifying Swap Txn"
					className={cn(
						formErrors['apiName'] ? 'border-destructive' : '',
						'col-span-2',
					)}
					value={formFields?.apiName}
					error={formErrors['apiName']}
					errorText={formErrors['apiName']}
					setValue={(e) =>
						setFormFields((prev: any) => ({ ...prev, apiName: e }))
					}
				/>
				<div className="col-span-1">
					<Label className="flex items-center mb-2">API Type</Label>
					<Select
						defaultValue={formFields?.apiCredentialsType}
						onValueChange={(newVal) => {
							setFormFields((prev: any) => ({
								...prev,
								apiCredentialsType: newVal,
								curlCommand: '',
							}));
							setParsedData(null);
						}}
						required
					>
						<SelectTrigger>
							<SelectValue placeholder="Choose the API type" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value={ApiCredentialTypes?.Rest}>
								REST
							</SelectItem>
							<SelectItem value={ApiCredentialTypes?.GraphQl}>
								GraphQL
							</SelectItem>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="mt-6 space-y-2">
				<Label>What action will your API verify?</Label>
				<Select
					name="apiVerificationAction"
					defaultValue={formFields?.apiVerificationAction}
					onValueChange={(newVal) =>
						setFormFields((prev: any) => ({
							...prev,
							apiVerificationAction: newVal,
						}))
					}
				>
					<SelectTrigger aria-label="Area" className="text-sm">
						<SelectValue placeholder="Select" className="h-10 " />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value={ApiVerification?.EVM}>
							On chain action on EVM Networks (Eg. Swap, Transfer)
						</SelectItem>
						<SelectItem value={ApiVerification?.NonEVM}>
							On chain action on non-EVM Networks
						</SelectItem>
						<SelectItem value={ApiVerification?.OffChain}>
							Off chain action (Eg. App sign up)
						</SelectItem>
					</SelectContent>
				</Select>
			</div>
			<ApiMeta />
		</div>
	);
};
