import { trackUserSession } from '@/features/getting-started/services/home.service';
import { getUtmParams } from '@/lib/utils';
import { useCallback, useEffect, useRef } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { useLocation } from 'react-router-dom';

export const useSessionTracking = () => {
	const { user } = useAuth();
	const location = useLocation();
	const hasTrackedSession = useRef(false);

	const saveUtmParams = useCallback(() => {
		const utmParams = getUtmParams();
		const storedUtmParams = JSON.parse(
			localStorage.getItem('utmParams') || '{}',
		);

		const hasNewUtmParams = Object.keys(utmParams).some(
			(key) =>
				utmParams[key] !== null && utmParams[key] !== storedUtmParams[key],
		);

		if (hasNewUtmParams) {
			const mergedUtmParams = { ...storedUtmParams, ...utmParams };
			localStorage.setItem('utmParams', JSON.stringify(mergedUtmParams));
		}
	}, []);

	const handleUserSessionTracking = useCallback(async () => {
		if (!user || hasTrackedSession.current) return;

		const storedUtmParams = JSON.parse(
			localStorage.getItem('utmParams') || '{}',
		);

		try {
			await trackUserSession(storedUtmParams);
			hasTrackedSession.current = true;
		} catch (error) {
			console.error('Failed to track user session:', error);
		}
	}, [user]);

	useEffect(() => {
		try {
			saveUtmParams();
		} catch (error) {
			console.error('Error saving UTM params:', error);
		}
	}, [saveUtmParams, location.search]);

	useEffect(() => {
		if (user && location.pathname === '/app/home') {
			handleUserSessionTracking();
		}
	}, [handleUserSessionTracking, user, location.pathname]);
};
