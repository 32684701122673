import { axios } from '@/lib/axios';

export const globalSearch = async (search: string): Promise<any> => {
	const response = await axios.get('/home/search?search=' + search);
	return response.data;
};

export const getSuggestedActions = async (): Promise<any> => {
	const response = await axios.get('/home/suggested-actions');
	return response.data;
};

export const getQuickAccess = async (body): Promise<any> => {
	const response = await axios.post('/home/quick-access', body);
	return response.data;
};

export const fetchGlobalConfigs = async (): Promise<any> => {
	const response = await axios.get('/home/global-configs');
	return response.data;
};

export const trackUserSession = async (data: {
	[key: string]: string;
}): Promise<any> => {
	const response = await axios.post('/home/track-session', {
		...data,
	});
	return response.data;
};
