import { getCookie } from './parsers';

export const getGASessionId = () => {
	const gaCookie = getCookie('_ga');
	if (!gaCookie) return null;

	const gaParts = gaCookie.split('.');
	// The session ID is typically the last part of the GA cookie
	return gaParts.length > 2 ? gaParts[2] : null;
};
