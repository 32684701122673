import { Input } from '@/components/plate-ui/input';
import { Button } from '@/components/ui/button';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { ApiDataEnums, ApiValueKeys } from '../types/task-apis.enum';
import React, { useRef, useEffect, useMemo } from 'react';

export const PayloadItem = ({ setItems, index }) => {
	const { formErrors, formFields, setFormFields, setFormErrors } = useConnectAPI();
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [formFields?.apiPayload?.[index]?.key]);

	const templateKeyMap = {
		wallet: 'address',
		'twitter-id': 'twitter',
		'twitter-handle': 'twitterHandle',
		'discord-id': 'discord',
		'discord-handle': 'discordHandle',
		'telegram-id': 'telegram',
		email: 'email',
	};

	const handlePayloadChange = (newVal: any) => {
		try {
			const tempApiPayload = [...(formFields?.apiPayload ?? [])];
			tempApiPayload[index] = {
				key: tempApiPayload[index]?.key,
				value: newVal,
				testValue: tempApiPayload[index]?.testValue,
			};
			const tempApiConstantDataFields = [
				...(formFields?.apiConstantDataFields ?? []),
			];

			if (newVal === ApiDataEnums.ConstantValue) {
				//add constant payload items
				tempApiConstantDataFields.push({
					key: tempApiPayload[index]?.key,
					value: tempApiPayload[index]?.testValue as string,
					testValue: tempApiPayload[index]?.testValue,
				});
			}

			setFormFields((prev: any) => ({
				...prev,
				apiPayload: tempApiPayload,
				apiConstantDataFields: tempApiConstantDataFields,
				isCustomConstantPayloadRequired:
					tempApiConstantDataFields?.length > 0,
			}));
		} catch (err) {
			console.log(err);
		}
	};

	const handleTemplateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value: newPayloadKey } = e.target;
		setFormFields((prev: any) => {
			const tempApiPayload = prev.apiPayload.map((item, idx) =>
				idx === index ? { ...item, key: newPayloadKey } : item,
			);
			return {
				...prev,
				apiPayload: tempApiPayload,
			};
		});

		// Move error clearing outside of setFormFields
		setFormErrors((prev: any) => ({
			...prev,
			[formFields?.apiPayload[index]?.key]: '',
		}));
	};

	const removePayloadItem = (keyToRemove) => {
		setItems((prevItems) => Math.max(prevItems - 1, 0));
		const updated = formFields.apiPayload.filter(
			(item) => item.key !== keyToRemove,
		);
		setFormFields((prev) => ({
			...prev,
			apiPayload: updated,
		}));
	};

	const availableApiValueKeys = useMemo(() => {
		const usedValues = new Set(formFields.apiPayload.map((item) => item.value));
		const filteredKeys = ApiValueKeys.filter(
			(item) =>
				!usedValues.has(item.value) ||
				item.value === formFields.apiPayload[index]?.value,
		);
		filteredKeys.push({
			value: ApiDataEnums.ConstantValue,
			label: 'Constant Value',
		});

		if (formFields?.isRecurringTasksEnabled) {
			filteredKeys.push(
				{
					value: ApiDataEnums.EventStartTimestamp,
					label: 'Start Timestamp',
				},
				{
					value: ApiDataEnums.EventEndTimestamp,
					label: 'End Timestamp',
				},
			);
		}

		return filteredKeys;
	}, [formFields.apiPayload, formFields.isRecurringTasksEnabled, index]);

	return (
		<div className="flex gap-2 py-1 px-0.5">
			<div className="flex flex-grow items-center gap-2 max-w-[14rem] z-[2]">
				<Input
					ref={inputRef}
					key={`payload-input-${index}`}
					className={`h-9 ${
						formErrors[formFields?.apiPayload?.[index]?.key]
							? 'border-destructive'
							: ''
					}`}
					value={formFields?.apiPayload?.[index]?.key || ''}
					onChange={handleTemplateChange}
				/>
			</div>
			<div
				className={`flex items-center flex-grow space-x-2 sm:space-x-0 max-w-[14rem]`}
			>
				<Select
					onValueChange={handlePayloadChange}
					value={formFields?.apiPayload?.[index]?.value}
				>
					<SelectTrigger aria-label="Area" className="text-sm">
						<SelectValue placeholder="Select" className="h-10 " />
					</SelectTrigger>
					<SelectContent>
						{availableApiValueKeys.map((item) => (
							<SelectItem key={item.value} value={item.value}>
								{item.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>

			<div className="flex items-center justify-end">
				<Button
					variant="outline"
					size="sm"
					className="w-9 h-9"
					onClick={() =>
						removePayloadItem(formFields?.apiPayload?.[index]?.key)
					}
				>
					<i className="bi bi-x-lg"></i>
				</Button>
			</div>
		</div>
	);
};
