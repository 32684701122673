import { Media } from '@/components/element/media/Media';
import { links } from '@/config/links';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import analytics from '@/lib/analytics';
import { cn } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import EditTaskLayout from './components/Layout/EditTaskLayout';
import TaskDraggableLayout from './components/Layout/TaskDraggableLayout';
import TasksAndTaskGroups from './components/Layout/TasksAndTaskGroups';
import { AddTaskBtn } from './components/Templates/AddTaskBtn';

const EditTasks = () => {
	const { isReordering } = useCreateCampaign();
	const { tasks, errors } = useCreateCampaignTasks();

	return (
		<div className="w-full">
			<EditTaskLayout>
				<TaskDraggableLayout className="flex flex-col items-center justify-center">
					{tasks &&
						Array.isArray(tasks) &&
						tasks.map((i, index) => {
							return (
								<Draggable
									key={i.id}
									draggableId={i.id}
									index={index}
									isDragDisabled={!isReordering}
								>
									{(provided, snapshot) => {
										return (
											<div
												ref={provided.innerRef}
												className="notranslate"
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<TasksAndTaskGroups
													task={i}
													isDragging={snapshot.isDragging}
													taskIndex={index}
												/>
											</div>
										);
									}}
								</Draggable>
							);
						})}
				</TaskDraggableLayout>

				{!isReordering && <AddTaskBtn />}
			</EditTaskLayout>
			{tasks?.length < 1 && errors?.isEmpty && (
				<div className="text-destructive bg-slate-100 text-xs px-24 pt-2 mb-4 rounded-lg text-center">
					* At least one task is required to create a campaign
				</div>
			)}

			{tasks?.length > 0 ? null : (
				<TaskCreationNudge
					isErrorTextVisible={errors?.isEmpty && tasks?.length < 1}
				/>
			)}
		</div>
	);
};

export default EditTasks;

const TaskCreationNudge = ({
	isErrorTextVisible,
}: {
	isErrorTextVisible?: boolean;
}) => {
	const handleClick = () => {
		analytics.track(TrackingEvents.QuickSummaryLinkClicked, {});
	};

	return (
		<div
			className={cn(
				'border border-dashed rounded-xl z-[2] bg-slate-100  p-10 border-gray-300 w-[52.3rem] mx-auto',
				isErrorTextVisible ? 'mt-2' : 'mt-4',
			)}
		>
			<Media
				src="https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/4ab64c67b860492795236e548b3bf8ff.svg"
				altText="task-placeholder"
				className="text-center mx-auto"
			/>
			<p className="my-6 font-medium text-center text-base leading-normal text-slate-600">
				Click on "Add task" to start creating quest
			</p>
			<ul className="space-y-2 text-slate-600 text-sm leading-normal font-normal list-disc pl-5">
				<li>
					Add 5-7 tasks at max, for achieving higher completions. Lesser
					tasks means greater number of completions!
				</li>
				<li>
					Check out our{' '}
					<Link
						to={links.docs.bestPractices}
						target="_blank"
						rel="noopener noreferrer"
						onClick={handleClick}
						className="underline underline-offset-4 decoration-purple-400 decoration-2"
					>
						quick summary
					</Link>{' '}
					of the best quest creation practices.
				</li>
			</ul>
		</div>
	);
};
