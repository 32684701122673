import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { links } from '@/config/links';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useConnectAPI } from '../hooks/useConnectAPI';
import useCreateTaskAPI from '../hooks/useCreateTaskAPI';
import { CreateTaskAPISteps, EvaluationFields } from '../types/task-apis.enum';
import { ApiDetailsV2 } from './ApiDetailsV2';
import CurlParser from './CurlParser';
import EvaluateResponse from './EvaluateResponse';
import { PayloadDetails } from './PayloadDetails';
import { TestAPI } from './TestAPI';

const ConnectAPIDialog = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
	const {
		setFormFields,
		isLoading,
		step,
		setStep,
		formFields,
		formErrors,
		setFormErrors,
		parsedData,
		setParsedData,
		evaluationErrors,
		evaluatedFields,
	} = useConnectAPI();

	const { prevStepMap, handleClick, handleStartOver } = useCreateTaskAPI({
		setOpen,
	});

	const goBack = () => {
		if (prevStepMap[step]) {
			setStep(prevStepMap[step]);
			setFormFields((prev: any) => ({
				...prev,
				isApiTestDone: false,
			}));
		}
	};

	const isTesting = useMemo(() => {
		return (
			step === CreateTaskAPISteps.Testing ||
			step === CreateTaskAPISteps.ReTesting
		);
	}, [step]);

	const isDisabled = useMemo(() => {
		if (step === CreateTaskAPISteps.EvaluateResponse) {
			// Check if any evaluationErrors key has error
			const hasErrors = Object.values(evaluationErrors).some(
				(error) => !!error,
			);

			const requiredFields = [EvaluationFields.DataResult];

			if (formFields?.isMetricBasedTaskEnabled) {
				requiredFields.push(
					EvaluationFields.DataMetric,
					EvaluationFields.DataMetricDataType,
				);
			}

			const allRequiredFieldsEvaluated = requiredFields.every(
				(field) => evaluatedFields[field],
			);

			return hasErrors || !allRequiredFieldsEvaluated || isLoading;
		}
		return isLoading;
	}, [
		evaluationErrors,
		evaluatedFields,
		isLoading,
		step,
		formFields?.isMetricBasedTaskEnabled,
	]);

	const showToolTip = useMemo(() => {
		if (step === CreateTaskAPISteps.EvaluateResponse && isDisabled) {
			return true;
		}
		return false;
	}, [isDisabled, step]);

	const getButtonLabel = useMemo(() => {
		switch (step) {
			case CreateTaskAPISteps.Testing:
			case CreateTaskAPISteps.ReTesting:
				return 'Test API';
			case CreateTaskAPISteps.EvaluateResponse:
				return 'Test Run';
			case CreateTaskAPISteps.Upsert:
				return 'Save API';
			default:
				return 'Next';
		}
	}, [step]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[555px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="flex justify-between">
						{isTesting ? 'Test with your data' : 'Connect your API'}
						<Link
							to={links?.docs?.apiCreation}
							target="_blank"
							className="text-sm font-normal text-normal text-muted-foreground hover:text-primary/80 hover:underline"
						>
							<i className="bi-question-circle me-1"></i>Guidelines
						</Link>
					</DialogTitle>
					<DialogDescription>
						{isTesting
							? 'Add your payload data to test the API response'
							: 'Add your API details to verify on-chain tasks.'}
					</DialogDescription>
				</DialogHeader>
				<div className="max-h-[30rem] overflow-auto px-1">
					{step === CreateTaskAPISteps.Basic && <ApiDetailsV2 />}
					{step === CreateTaskAPISteps.Curl && (
						<CurlParser
							formFields={formFields}
							setFormFields={setFormFields}
							formErrors={formErrors}
							setFormErrors={setFormErrors}
							parsedData={parsedData}
							setParsedData={setParsedData}
						/>
					)}
					{step === CreateTaskAPISteps.Payload && <PayloadDetails />}
					{isTesting && (
						// || step === CreateTaskAPISteps.Upsert
						<TestAPI setStep={setStep} />
					)}
					{step === CreateTaskAPISteps.EvaluateResponse ||
					step === CreateTaskAPISteps.Upsert ? (
						<EvaluateResponse />
					) : null}
				</div>
				<DialogFooter
					className={`justify-between space-x-2 border-t pt-3 flex sm:justify-between `}
				>
					{step !== CreateTaskAPISteps.ReTesting &&
					step !== CreateTaskAPISteps.Basic ? (
						<div className="flex">
							<Button variant="outline" onClick={() => goBack()}>
								Back
							</Button>
						</div>
					) : (
						<div></div>
					)}
					<div className="flex gap-2">
						<Button variant="ghost" onClick={() => handleStartOver()}>
							Cancel
						</Button>
						<TooltipWrapper
							tooltip={
								showToolTip ? 'Evaluate the expressions first' : ''
							}
							align="end"
						>
							<Button
								onClick={() => handleClick()}
								disabled={isDisabled}
							>
								{isLoading ? (
									<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
								) : null}
								{getButtonLabel}
							</Button>
						</TooltipWrapper>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ConnectAPIDialog;
