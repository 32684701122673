import { SelectLeaderboard } from '../components/SelectLeaderboard';
import { Button } from '@/components/ui/button';
import LeaderboardTable from '../components/table/LeaderboardTable';
import { useLeaderboard } from '../hooks/useLeaderboard';
import Spinner from '@/components/element/loading/Spinner';
import PageHeader from '@/components/layout/PageHeader';
import NoDataCard from '@/components/element/cards/NoDataCard';
import leaderboard from '@/assets/empty/light/leaderboard.webp';
import { useNavigate } from 'react-router-dom';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';

const LeaderboardPage = () => {
	const navigate = useNavigate();
	const {
		isLoading,
		users,
		totalCount,
		selectedLeaderboard,
		setSelectedLeaderboard,
		pagination,
		setPagination,
		setSorting,
		sorting,
		exportLeaderboard,
		isExporting,
	} = useLeaderboard({});

	return (
		<div>
			<PageHeader
				title="Leaderboard"
				description="Create and manage leaderboards for your campaigns"
			/>
			{totalCount === 0 && !selectedLeaderboard ? (
				<NoDataCard
					title="See Who’s Leading the Way"
					description="Launch your first quest and watch as users climb the leaderboard! Start now to fuel drive participation"
					image={leaderboard}
					className="mt-10"
					btnLabel="Create Quest"
					btnOnClick={() => {
						navigate('/app/campaign/quests/new');
					}}
				/>
			) : (
				<div>
					<div className="mb-5 mt-10 flex justify-between">
						<div className="flex space-x-6">
							<SelectLeaderboard
								selectedLeaderboard={selectedLeaderboard}
								setSelectedLeaderboard={setSelectedLeaderboard}
							/>
						</div>
						<div>
							<Button variant="outline" onClick={exportLeaderboard}>
								{isExporting ? (
									<Spinner className="me-2" />
								) : (
									<i className="bi-download me-2"></i>
								)}
								<span>Download</span>
							</Button>
						</div>
					</div>
					<LeaderboardTable
						isLoading={isLoading}
						users={users}
						totalCount={totalCount}
						showCampaignXps={selectedLeaderboard?.id !== 'overall'}
						pagination={pagination}
						setPagination={setPagination}
						sorting={sorting}
						setSorting={setSorting}
						showHumanProbability
					/>
				</div>
			)}
		</div>
	);
};

export default LeaderboardPage;
