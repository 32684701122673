import SeparatorWithText from '@/components/ui/seprator-with-text';
import {
	ContractType,
	ITask,
	ITaskAdminInput,
	TaskKeys,
	TemplateFamily,
	TemplateType,
} from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import TaskInputs from './inputs/TaskInputs';
import {
	IEditingTasks,
	useCreateCampaignTasks,
} from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import InputText from '@/components/element/inputs/InputText';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import { useEffect, useMemo, useState } from 'react';
import { findContractType } from '@/features/campaigns/services/campaigns.service';
import { ErrorTypes, handleErrorMessage } from '@/utils/notifications';

const TaskParams = ({
	adminInputs,
	templateFamily,
	templateType,
	requiredLogins,
	description,
	taskId,
	isCustomInitiationURLAllowed,
	customInitiationURL,
	isDisabled,
	isAdminRequiredLoginsSelectionNeeded,
	requiredLoginOptions,
}: IEditingTasks['task']) => {
	const { setErrors } = useCreateCampaign();
	const { errors, editTaskAdminInput, editTask, deleteTask } =
		useCreateCampaignTasks();

	const [showTokenIdForErc1155, setShowTokenIdForErc1155] = useState(false);

	const nftContractAddress = adminInputs?.find(
		(i) => i.key === TaskKeys?.NftAddress,
	);
	const chainId = adminInputs?.find((i) => i.key === TaskKeys?.NftChainId);

	useEffect(() => {
		if (chainId?.value && nftContractAddress?.value) {
			findContractType(
				chainId.value as number,
				nftContractAddress.value as string,
			)
				.then((res) => {
					const shouldShow =
						templateType === TemplateType.HoldingNft &&
						res.contractType === ContractType.Erc1155;
					setShowTokenIdForErc1155(shouldShow);
				})
				.catch((e) => {
					if (e?.response?.data?.code === ErrorTypes.ServerError)
						handleErrorMessage(
							'Invalid Contract Address. Please check and try again.',
						);
					setShowTokenIdForErc1155(false);
				});
		}
	}, [adminInputs, templateType, chainId?.value, nftContractAddress?.value]);

	const filteredAdminInputs = useMemo(() => {
		return adminInputs?.filter((i) => {
			if (i.key === TaskKeys.Erc1155TokenId) {
				return showTokenIdForErc1155;
			}
			return !i.hide;
		});
	}, [adminInputs, showTokenIdForErc1155]);

	return (
		<div>
			<SeparatorWithText
				className="mt-4 mb-3"
				text="Task Configuration"
				align="end"
			/>
			<div className={`grid py-2 grid-cols-12  gap-5`}>
				{filteredAdminInputs?.map((i) => (
					// Could be potential breakable code. Please review this once.
					<div key={`${i.key}-${i.inputType}`} className={cn(`${i.size}`)}>
						<TaskInputs
							adminInput={i}
							adminInputs={adminInputs}
							templateFamily={templateFamily}
							templateType={templateType}
							description={description}
							taskId={taskId}
							deleteTask={deleteTask}
							error={errors?.[taskId]?.adminInputs?.[i.key]}
							editTaskAdminInput={editTaskAdminInput}
							setErrors={(taskId, key, value) => {
								setErrors((prev: any) => ({
									...prev,
									tasks: {
										...prev?.tasks,
										[taskId]: {
											...prev.tasks?.[taskId],
											adminInputs: {
												...prev.tasks?.[taskId]?.adminInputs,
												[key]: value,
											},
										},
									},
								}));
							}}
							src="create-campaign"
						/>
					</div>
				))}
				{isCustomInitiationURLAllowed && !isDisabled && (
					<div className="col-span-12 mt-4">
						<InputText
							value={customInitiationURL}
							setValue={(e) => {
								editTask(taskId, 'CUSTOM_INITIATION_URL', e);
							}}
							label="Initiation URL (optional)"
							placeholder="https://www.example.com"
							subLabel="Add link where users will be redirected to start the task."
							subLabelClassName="font-normal text-xs mt-1"
							error={errors?.[taskId]?.customInitiationURL}
							errorText="Invalid URL"
						/>
					</div>
				)}
				{isAdminRequiredLoginsSelectionNeeded && !isDisabled && (
					<div className="col-span-12 mt-4">
						<InputSelect2
							value={requiredLogins?.[0]}
							setValue={(e) => {
								editTask(taskId, 'REQUIRED_LOGIN', e);
							}}
							options={
								requiredLoginOptions?.map((i) => ({
									label: i,
									value: i,
								})) ?? []
							}
							label="Required Login"
							subLabel="Select the login method required to complete the task."
							subLabelClassName="font-normal text-xs mt-1 mb-2"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default TaskParams;
