import { useQuery } from '@tanstack/react-query';
import { fetchRewardDetails } from '../../services/campaigns.service';
import { useCampaignReport } from './useCampaignReport';
import { useCallback, useMemo, useState } from 'react';
import { useCampaignWithdrawFunds } from './useCampaignWithdrawFunds';
import dayjs from 'dayjs';
import { CampaignRewardCategory, Status } from '../../types';

const useCampaignRewards = () => {
	const { campaign } = useCampaignReport();

	const {
		isLoading: isWithdrawInProgress,
		claimTokens,
		isClaimable,
	} = useCampaignWithdrawFunds();

	const {
		data = [],
		isLoading,
		error,
	} = useQuery({
		queryKey: ['campaign', campaign?._id, 'rewards'],
		queryFn: () => fetchRewardDetails(campaign?._id),
		enabled: !!campaign?._id,
	});

	const [currentIndex, setCurrentIndex] = useState(0);

	const currentReward = useMemo(
		() => (data && data.length > 0 ? data[currentIndex] : null),
		[data, currentIndex],
	);

	const handleNext = useCallback(() => {
		if (data.length > 0) {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
		}
	}, [data.length]);

	const handlePrev = useCallback(() => {
		if (data.length > 0) {
			setCurrentIndex(
				(prevIndex) => (prevIndex - 1 + data.length) % data.length,
			);
		}
	}, [data.length]);

	const tooltipText = useMemo(() => {
		if (campaign?.tokenRewardWithdrawal?.isWithdrawn) {
			return 'You have already withdrawn the remaining tokens.';
		} else if (!isClaimable) {
			return `You can withdraw pending tokens after 30 days of campaign completion, i.e. ${dayjs(new Date(campaign?.claimDate)).fromNow()}`;
		} else if (campaign.status === Status.Completed) {
			return 'You can withdraw the remaining amount.';
		} else {
			return 'You can withdraw your tokens after the campaign is completed.';
		}
	}, [
		campaign?.tokenRewardWithdrawal?.isWithdrawn,
		isClaimable,
		campaign.status,
		campaign.claimDate,
	]);

	return {
		rewardDetails: data,
		isLoading,
		error,
		currentReward,
		handleNext,
		handlePrev,
		currentIndex,
		tooltipText,
		campaignStatus: campaign?.status,
	};
};

export default useCampaignRewards;
