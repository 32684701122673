import InputSelect2 from '@/components/element/inputs/InputSelect2';
import InputText from '@/components/element/inputs/InputText';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import { Separator } from '@/components/ui/separator';
import { useCreateActionProvider } from '../../hooks/actions/useCreateActionProvider';
import SelectActionType from './details/SelectActionType';
import SelectContract from './details/SelectContract';
import SelectAddress from './details/SelectAddress';

const ActionDetails = () => {
	const { details, setDetails, errors } = useCreateActionProvider();

	return (
		<div>
			<div className="space-y-10">
				<InputText
					label="Name"
					subLabel="This is the name of the action. Eg. Swapping in searchTextH/USDC pool"
					subLabelClassName="text-xs text-muted-foreground font-normal mt-2"
					value={details.name}
					setValue={(e) => setDetails({ ...details, name: e })}
					error={errors.name}
					placeholder="Enter action name"
					inputClassName="bg-white dark:bg-gray-800 rounded-s-none"
					prepend={<i className="bi-type text-muted-foreground px-3"></i>}
				/>
				<InputSelect2
					label="Category"
					subLabel="What category does this action fall under? Eg. searchText, Bridge, etc."
					subLabelClassName="text-xs text-muted-foreground font-normal mb-2"
					value={details.category}
					setValue={(e) => setDetails({ ...details, category: e })}
					error={errors.category}
					placeholder="Select action category"
					options={categories}
					inputClassName="bg-white dark:bg-gray-800 hover:bg-white"
					appendIcon="bi-tags-fill"
				/>
				<InputTextArea
					label="Description"
					value={details.description}
					setValue={(e) => setDetails({ ...details, description: e })}
					error={false}
					placeholder="Enter action description"
					inputClassName="bg-white dark:bg-gray-800"
				/>
				<SelectActionType
					value={details.type}
					setValue={(e: string) => setDetails({ ...details, type: e })}
				/>
				<Separator />
				{details.type === 'contract' && <SelectContract />}
				{details.type === 'address' && <SelectAddress />}
			</div>
		</div>
	);
};

export default ActionDetails;

const categories = [
	{ value: 'swap', searchText: 'Swap', label: 'Swap' },
	{ value: 'bridge', searchText: 'Bridge', label: 'Bridge' },
	{ value: 'liquidity', searchText: 'LP', label: 'LP' },
	{ value: 'stake', searchText: 'Staking', label: 'Staking' },
	{ value: 'yield_farming', searchText: 'Yield Farming', label: 'Yield Farming' },
	{ value: 'borrow', searchText: 'Borrowing', label: 'Borrowing' },
	{ value: 'lend', searchText: 'Lending', label: 'Lending' },
	{ value: 'mint', searchText: 'Minting', label: 'Minting' },
	{ value: 'burn', searchText: 'Burning', label: 'Burning' },
	{ value: 'vote', searchText: 'Voting', label: 'Voting' },
	{ value: 'claim_rewards', searchText: 'Claim Rewards', label: 'Claim Rewards' },
	{
		value: 'provide_liquidity',
		searchText: 'Provide Liquidity',
		label: 'Provide Liquidity',
	},
	{
		value: 'remove_liquidity',
		searchText: 'Remove Liquidity',
		label: 'Remove Liquidity',
	},
	{ value: 'nft_mint', searchText: 'NFT Minting', label: 'NFT Minting' },
	{ value: 'nft_burn', searchText: 'NFT Burning', label: 'NFT Burning' },
	{ value: 'nft_transfer', searchText: 'NFT Transfer', label: 'NFT Transfer' },
	{ value: 'nft_sell', searchText: 'NFT Sell', label: 'NFT Sell' },
	{ value: 'nft_buy', searchText: 'NFT Buy', label: 'NFT Buy' },
	{
		value: 'governance_vote',
		searchText: 'Governance Voting',
		label: 'Governance Voting',
	},
	{ value: 'airdrop_claim', searchText: 'Airdrop Claim', label: 'Airdrop Claim' },
	{ value: 'trading', searchText: 'Trading', label: 'Trading' },
];
