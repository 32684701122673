import { axios } from '@/lib/axios';
import {
	IAPIFormFields,
	IApiOutputExpressions,
	IApiRequest,
} from '../types/task-apis.type';
import { ApiCredentialTypes } from '../types/task-apis.enum';

export const testAPI = async (data: {
	type: string;
	apiDataFields: any;
	apiDataPassingMethod: string;
	apiEndpoint: string;
	apiHeaders: any;
	apiMethod: string;
	apiName: string;
}): Promise<{
	isResponseSchemaValid: boolean;
	response: any;
}> => {
	const response = await axios.post('api-requests/test', data);
	return response.data;
};

export const apiRequests = async (): Promise<IApiRequest[]> => {
	const response = await axios.get('api-requests');
	return response.data;
};

export const createAPI = async (data: {
	_id: string;
	apiDataFields: any;
	apiDataPassingMethod: string;
	apiEndpoint: string;
	apiHeaders: any;
	apiMethod: string;
	apiName: string;
	apiVerificationFor: string;
	isRecurringTaskEnabled: boolean;
	isMetricBasedTaskEnabled: boolean;
	apiCredentialsType: ApiCredentialTypes;
	apiOutputExpressions?: IApiOutputExpressions;
	apiConstantDataFields?: any;
	graphQlQuery?: string;
}): Promise<IApiRequest> => {
	const response = await axios.post('api-requests', data);
	return response.data;
};

export const deleteTaskAPI = async (data: any) => {
	const response = await axios.delete(`api-requests`, {
		data,
	});
	return response.data;
};

export const testApiV2 = async (data: {
	type: string;
	apiDataFields: any;
	apiDataPassingMethod: string;
	apiEndpoint: string;
	apiHeaders: any;
	apiMethod: string;
	apiName: string;
	apiOutputExpressions: {
		result: {
			expression: string;
		};
		metric?: {
			expression: string;
		};
		metricDataType?: {
			expression: string;
		};
		code?: {
			expression: string;
		};
		message?: {
			expression: string;
		};
	};
	apiConstantDataFields: any;
	isMetricBasedTaskEnabled: boolean;
	graphQlQuery?: string;
	apiCredentialsType: ApiCredentialTypes;
}): Promise<{
	isExpressionsValid: {
		isValid: boolean;
		message: string;
		key?: string;
	};
	response: any;
}> => {
	const response = await axios.post('api-requests/test-v2', data);
	return response.data;
};
