import { Media } from '@/components/element/media/Media';
import { homepageContent, testimonials } from '../../content/homepage.content';
import SectionHeader from '../ui/SectionHeader';
import { cn } from '@/lib/utils';
import DOMPurify from 'dompurify';

const Testimonials = () => {
	return (
		<div className="mx-auto px-2.5 lg:px-20 relative pt-10 pb-28 lg:pb-0 my-20 overflow-hidden">
			<SectionHeader
				title={homepageContent.testimonials.title}
				description={homepageContent.testimonials.description}
				titleGradient={homepageContent.testimonials.titleGradient}
			/>

			<ul className="flex items-end space-y-6 space-x-16 py-8 animate-marquee">
				{testimonials.map((testimonial, index) => (
					<TestimonialCard key={index} {...testimonial} />
				))}
			</ul>
		</div>
	);
};

export default Testimonials;

const TestimonialCard = ({
	companyLogo,
	testimonial,
	name,
	profile,
	backgroundVariant,
	isDark,
	heading,
}) => {
	const safeHTML = DOMPurify.sanitize(testimonial);

	return (
		<li
			className={`min-w-[24.5rem] h-[22rem] group relative list-none break-inside-avoid rounded-3xl border border-black bg-black/90 backdrop-blur-lg transition-all hover:scale-105`}
		>
			<div className="flex flex-col justify-between px-10 py-9 bg-white rounded-3xl rounded-t-[22px] rounded-b-xl h-[18rem]">
				<p className={`text-xl font-bold mb-4 text-center`}>{heading}</p>
				<div
					className={cn(
						'flex-grow max-h-[14rem] overflow-y-auto text-[#7E7E7E] font-normal text-base',
					)}
					dangerouslySetInnerHTML={{ __html: safeHTML }}
				></div>
				<div className="absolute bottom-4 left-10 flex items-center">
					{/* <div className="rounded-lg size-12 flex items-center me-2">
						<Media
							src={companyLogo}
							altText={name}
							className="rounded-lg"
						/>
					</div> */}
					<div className="flex gap-1 text-center">
						<div className={`text-base font-normal text-white`}>
							{name},
						</div>
						<div className={`text-base font-normal text-white/60`}>
							{profile}
						</div>
					</div>
				</div>
			</div>
		</li>
	);
};
