import { useParams } from 'react-router-dom';
import { useCampaignReport } from './useCampaignReport';
import { useAccount } from 'wagmi';
import { useReadContracts } from '@/hooks/useReadContracts';
import { useEffect, useMemo, useState } from 'react';
import { CampaignRewardCategory } from '../../types';
import { ERC20RewardABI } from '@/config/abi/ERC20RewardABI';
import { formatObjectID } from '@/utils/parsers';
import { useCreateTxn } from '@/hooks/useCreateTxn';
import { markCampaignTokenRewardWithdrawn } from '../../services/campaigns.service';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';

export const useCampaignWithdrawFunds = () => {
	const { id } = useParams();
	const { campaign } = useCampaignReport();
	const [isClaimable, setIsClaimable] = useState(false);
	const [txnStatus, setTxnStatus] = useState({
		isFailed: false,
		isSucceeded: false,
	});

	const { address, chainId: selectedChainId } = useAccount();
	const { startTxn } = useCreateTxn();
	const { readContract } = useReadContracts();
	const [isLoading, setIsLoading] = useState(false);

	const tokenReward = useMemo(() => {
		const tokenReward = campaign?.reward?.find(
			(i) => i.category === CampaignRewardCategory.Token,
		);
		return tokenReward;
	}, [campaign]);

	const campaignId = useMemo(() => {
		return formatObjectID(id)?.toString();
	}, [id]);

	const claimTokens = async () => {
		try {
			setIsLoading(true);
			const txn = await startTxn({
				chainId: tokenReward?.tokenReward?.chainId,
				contractAddress: campaign?.vaultAddress,
				ABI: ERC20RewardABI,
				fnName: 'withdraw',
				args: [campaignId],
			});
			await markCampaignTokenRewardWithdrawn(id, txn);
			queryClient.invalidateQueries({
				queryKey: ['campaign', id],
			});
			setTxnStatus({
				isFailed: false,
				isSucceeded: true,
			});
			console.log(txn);
		} catch (err) {
			setTxnStatus({
				isFailed: true,
				isSucceeded: false,
			});
			console.log(err);
			handleErrorMessage(err);
			setTimeout(() => {
				setTxnStatus({
					isFailed: false,
					isSucceeded: false,
				});
			}, 1000);
		} finally {
			setIsLoading(false);
		}
	};

	const checkClaimDate = async () => {
		try {
			const campaignConfig = await readContract({
				chainId: tokenReward?.tokenReward?.chainId,
				contractAddress: campaign?.vaultAddress,
				ABI: ERC20RewardABI,
				fnName: 'campaignConfigs',
				args: [campaignId],
			});

			const claimDate = new Date(+campaignConfig[2].toString() * 1000);
			if (claimDate < new Date()) {
				setIsClaimable(true);
			} else {
				setIsClaimable(false);
			}
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	useEffect(() => {
		if (!campaign || !tokenReward) {
			setIsClaimable(false);
		} else if (new Date(campaign.claimDate) > new Date()) {
			setIsClaimable(false);
		} else if (campaign?.tokenRewardWithdrawal?.isWithdrawn) {
			setIsClaimable(false);
		} else checkClaimDate();
	}, [campaign, tokenReward]);

	return {
		isClaimable,
		claimTokens,
		isLoading,
		txnStatus,
	};
};
