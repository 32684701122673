import { useIntercom } from '@/hooks/useIntercom';
import useScrollToTop from '@/hooks/useScrollToTop';
import { useSessionTracking } from '@/hooks/useSessionTracking';
import analytics from '@/lib/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GlobalLayout = ({ children }) => {
	const location = useLocation();
	const scrollToTop = useScrollToTop();

	useIntercom();
	useSessionTracking();

	useEffect(() => {
		analytics.pageView(location.pathname, {
			title: document.title,
			search: location.search,
		});
		scrollToTop();
	}, [location, scrollToTop]);

	return <div>{children}</div>;
};

export default GlobalLayout;
