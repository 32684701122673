import PageHeader from '@/components/layout/PageHeader';
import ConnectAPIDialog from '../components/ConnectAPIDialog';
import NoDataCard from '@/components/element/cards/NoDataCard';
import { DataTable } from '@/components/element/data-table/DataTable';
import { useGetTaskAPIs } from '../hooks/useGetTaskAPIs';
import InputText from '@/components/element/inputs/InputText';
import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import { links } from '@/config/links';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { TaskAPIStatus } from '../types/task-apis.enum';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { useQueryInitialise } from '@/hooks/useQueryInitialise';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const TaskAPIsPage = () => {
	const { resetForm } = useConnectAPI();
	const {
		apiRequestsQuery,
		taskApiColumns,
		showNew,
		setShowNew,
		filteredAPIs,
		filters,
		setFilters,
		search,
		setSearch,
	} = useGetTaskAPIs();

	const handleOpen = () => {
		analytics.track(TrackingEvents.TaskAPIsCreateViewed, {
			isUpdate: false,
		});
		setShowNew(true);
		// resetForm();
	};
	useQueryInitialise('new', 'true', () => {
		handleOpen();
	});

	if (apiRequestsQuery.isLoading) return <FullPageLoading />;

	return (
		<div>
			<PageHeader
				title="Integrate your APIs"
				description="Integrate your APIs to create custom tasks in your quests"
				btnLabel="Connect your API"
				btnOnClick={() => handleOpen()}
				learn={
					apiRequestsQuery?.data?.length === 0 ? links.docs.taskAPIs : ''
				}
				icon={'plug-fill'}
				btnLabel2="API Docs"
				btnOnClick2={() => window.open(links.docs.apiCreation, '_blank')}
			/>

			<div>
				{apiRequestsQuery?.data?.length === 0 ? (
					<NoDataCard
						title="No API connected yet"
						description="Connect your API to create custom quests and campaigns"
						btnLabel="Connect your API"
						btnOnClick={() => handleOpen()}
						className="mt-10"
						image="https://framerusercontent.com/images/axBztP7wGIM2Oz1MQwfrxJ0lg.png"
					/>
				) : (
					<>
						<div className="flex items-center mt-10 mb-5 space-x-4">
							<InputText
								value={search}
								setValue={setSearch as any}
								prepend={
									<i className="bi-search px-3 text-muted-foreground text-sm"></i>
								}
								placeholder="Search APIs"
								className="w-[250px] lg:w-[250px]"
							/>
							<FacetedFilter
								title="Status"
								options={apiFilters}
								setSelectedValues={(values: any) =>
									setFilters((prev) => ({
										...prev,
										status: values,
									}))
								}
								selectedValues={filters.status}
							/>
						</div>
						<DataTable
							columns={taskApiColumns}
							data={filteredAPIs}
							isLoading={apiRequestsQuery.isLoading}
							totalCount={apiRequestsQuery.data?.length || 0}
						/>
					</>
				)}
			</div>
			<ConnectAPIDialog open={showNew} setOpen={setShowNew} />
		</div>
	);
};

export default TaskAPIsPage;

const apiFilters = [
	{
		value: TaskAPIStatus.Approved,
		label: <StatusBadge status={TaskAPIStatus.Approved} />,
	},
	{
		value: TaskAPIStatus.Rejected,
		label: <StatusBadge status={TaskAPIStatus.Rejected} />,
	},
	{
		value: TaskAPIStatus.Submitted,
		label: <StatusBadge status={TaskAPIStatus.Submitted} />,
	},
	{
		value: TaskAPIStatus.UnderReview,
		label: <StatusBadge status={TaskAPIStatus.UnderReview} />,
	},
];
