import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { javascript } from '@codemirror/lang-javascript';
import { tags as t } from '@lezer/highlight';
import { createTheme } from '@uiw/codemirror-themes';
import CodeMirror from '@uiw/react-codemirror';
import { useConnectAPI } from '../hooks/useConnectAPI';
import useEvaluateExpressions from '../hooks/useEvaluateExpressions';
import { EvaluationFields } from '../types/task-apis.enum';
import { toTitleCase } from '@/utils/parsers';
import { cn } from '@/lib/utils';
import { useState } from 'react';
import { Separator } from '@/components/ui/separator';

const myTheme = createTheme({
	theme: 'light',
	settings: {
		background: '#ffffff',
		backgroundImage: '',
		foreground: '#75baff',
		caret: '#5d00ff',
		selection: '#036dd626',
		selectionMatch: '#036dd626',
		lineHighlight: '#8a91991a',
		gutterBackground: '#fff',
		gutterForeground: '#8a919966',
	},
	styles: [
		{ tag: t.comment, color: '#787b8099' },
		{ tag: t.variableName, color: '#a67ef6' },
		{ tag: [t.string, t.special(t.brace)], color: '#5c6166' },
		{ tag: t.number, color: '#5c6166' },
		{ tag: t.bool, color: '#5c6166' },
		{ tag: t.null, color: '#5c6166' },
		{ tag: t.keyword, color: '#5c6166' },
		{ tag: t.operator, color: '#5c6166' },
		{ tag: t.className, color: '#5c6166' },
		{ tag: t.definition(t.typeName), color: '#5c6166' },
		{ tag: t.typeName, color: '#5c6166' },
		{ tag: t.angleBracket, color: '#5c6166' },
		{ tag: t.tagName, color: '#5c6166' },
		{ tag: t.attributeName, color: '#5c6166' },
	],
});
const extensions = [javascript({ jsx: true })];

const EvaluateResponse = () => {
	const {
		formFields,
		setFormFields,
		evaluationErrors,
		setEvaluationErrors,
		evaluatedFields,
		setEvaluatedFields,
		setStep,
	} = useConnectAPI();

	const {
		expressionValue,
		openAccordion,
		errors,
		evaluationFields,
		handleAccordionChange,
		handleMetricDataTypeChange,
		handleExpressionValueChange,
		handleEvaluateWrapper,
	} = useEvaluateExpressions({
		formFields,
		setFormFields,
		evaluationErrors,
		setEvaluationErrors,
		evaluatedFields,
		setEvaluatedFields,
		setStep,
	});

	return (
		<div>
			{formFields?.responseSchema && (
				<div className="space-y-4 min-h-[20rem]">
					<div>
						<h2 className="font-medium text-sm mb-2">Response</h2>
						<pre className="bg-gray-100 text-sm p-4 rounded-md overflow-x-auto">
							{JSON.stringify(formFields?.responseSchema, null, 2)}
						</pre>
					</div>
					{evaluationFields?.map((item) =>
						item?.show ? (
							<Accordion
								className="w-full"
								type="single"
								collapsible
								value={openAccordion}
							>
								<AccordionItem value={item.label} className="w-full">
									<AccordionTrigger
										className="text-left font-normal text-sm focus:ring-0 focus:outline-none focus:border-none focus-visible:ring-0 focus-visible:outline-none"
										onClick={() =>
											handleAccordionChange(item.label)
										}
									>
										<span>
											{toTitleCase(item.label)}
											{item.required && (
												<span className="text-red-400 ms-1">
													*
												</span>
											)}
										</span>
									</AccordionTrigger>
									<AccordionContent>
										<div className="flex  text-gray-500 transition-all h-full">
											{item.label ===
											EvaluationFields.DataMetricDataType ? (
												<DropdownMenu>
													<DropdownMenuTrigger asChild>
														<Button
															variant="outline"
															className="data-[state=open]:bg-muted h-9 w-[18rem]"
														>
															<span>
																{item.valueFrom ||
																	'Choose Metric data type'}
															</span>
															<i className="bi-chevron-down ms-2"></i>
														</Button>
													</DropdownMenuTrigger>
													<DropdownMenuContent
														align="start"
														className="w-[18rem]"
													>
														<DropdownMenuItem
															onClick={() => {
																handleMetricDataTypeChange(
																	'INTEGER',
																);
															}}
														>
															{'INTEGER'}
														</DropdownMenuItem>
														<Separator />
														<DropdownMenuItem
															onClick={() => {
																handleMetricDataTypeChange(
																	'DOUBLE',
																);
															}}
														>
															{'DOUBLE'}
														</DropdownMenuItem>
													</DropdownMenuContent>
												</DropdownMenu>
											) : (
												<div className="flex items-center justify-center">
													<CodeMirror
														value={item.valueFrom}
														height="200px"
														className="border w-[18rem]"
														theme={myTheme}
														extensions={extensions}
														onChange={(newVal) =>
															handleExpressionValueChange(
																item.label,
																newVal,
															)
														}
													/>
													{item.valueFrom ? (
														<Button
															size="icon"
															variant="ghost"
															className="p-0 -mx-2.5 z-[1] rounded-full bg-slate-50"
															onClick={(e) => {
																e.stopPropagation();
																handleEvaluateWrapper(
																	item.valueFrom,
																	item.label,
																	item.evaluationType,
																);
															}}
														>
															<i className="bi-play-circle text-4xl "></i>
														</Button>
													) : (
														<div className="mx-2"></div>
													)}
													<pre
														className={cn(
															'bg-gray-200 text-sm p-4 rounded-md overflow-x-auto w-[12rem] h-[203px] border',
															errors[item.label]
																? 'border-destructive bg-red-50'
																: evaluatedFields[
																			item
																				.label
																			// eslint-disable-next-line no-mixed-spaces-and-tabs
																	  ]
																	? 'border-green-500 bg-emerald-50'
																	: 'border-transparent',
														)}
													>
														{expressionValue[
															item?.label
														] !== undefined &&
														expressionValue[
															item?.label
														] !== ''
															? expressionValue[
																	item.label
																].toString()
															: '/**\n expression result\n**/'}
													</pre>
												</div>
											)}
										</div>
										<p
											className={
												'text-xs text-destructive text-right mt-2 pr-2'
											}
										>
											{errors[item.label]}
										</p>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						) : (
							<></>
						),
					)}
				</div>
			)}
		</div>
	);
};

export default EvaluateResponse;
