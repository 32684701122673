import { getGASessionId } from '@/utils/ga';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function getTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const adjustedIntercomLauncherPosition = (position: string) => {
	const intercomEle = document.getElementsByClassName(
		'intercom-lightweight-app-launcher',
	);
	if (intercomEle.length > 0) {
		const ele = intercomEle[0];
		(ele as any).style.bottom = position;
		console.log('Re-adjusted Intercom launcher position');
	}
};

export const getUtmParams = () => {
	const search = new URLSearchParams(location.search);
	const referrer = document.referrer;
	const sessionId = getGASessionId() || null;

	const params: Record<string, string> = {
		referrer: referrer || 'DIRECT',
		sessionId,
	};

	for (const [key, value] of search.entries()) {
		const normalizedKey = key
			.toLowerCase()
			.replace(/_(.)/g, (_, char) => char.toUpperCase());
		params[normalizedKey] = value;
	}

	const commonUtmParams = [
		'utmSource',
		'utmMedium',
		'utmCampaign',
		'utmTerm',
		'utmContent',
		'utmId',
	];

	const filteredParams = {
		referrer: params.referrer,
		sessionId: params.sessionId,
		...commonUtmParams.reduce(
			(acc, param) => {
				if (params[param] !== undefined && params[param] !== null) {
					acc[param] = params[param];
				}
				return acc;
			},
			{} as Record<string, string | null>,
		),
	};

	return filteredParams;
};

export const removeUtmParams = () => {
	const url = new URL(window.location.href);
	const paramsToDelete = [];

	url.searchParams.forEach((value, key) => {
		console.log(`Processing param: ${key}`);
		if (key.toLowerCase().startsWith('utm_')) {
			console.log(`Will remove param: ${key}`);
			paramsToDelete.push(key);
		}
	});

	// Remove UTM parameters
	paramsToDelete.forEach((key) => {
		console.log(`Removing param: ${key}`);
		url.searchParams.delete(key);
	});

	console.log('Updated URL:', url.href);

	// Update the URL without reloading the page
	window.history.replaceState({}, document.title, url.pathname + url.search);
};
